.career_banner_adjust {
    padding-left: 0;
    padding-right: 0;
    position: relative;
}

.career_banner_adjust img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    clip-path: circle(82% at 44% -34%);
}

.carreer_text_top_banner {
    margin-left: 57px;
    padding: 72px 40px 124px 43px;
}

.carreer_text_top_banner h2 {
    font-family: graphik_medium;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
    color: rgba(0, 0, 0, 1);
}

.carreer_text_top_banner h2 span {
    font-family: graphik_bold !important;
    color: #a23333 !important;
}

.career_option_top_text {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 70%;
    clip-path: circle(77% at 36% -55%);
}

.carreer_text_top_banner h4 {
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
}

.carreer_text_top_banner h4 span {
    color: #a23333 !important;
    font-weight: 600;
}

.carreer_text_top_banner p {
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    font-family: "Poppins", sans-serif;
    color: rgba(87, 87, 87, 1);
    width: 65%;
}

.career_filter_text {
    background-color: #ffeff1;
    padding: 8px 10px;
    margin-bottom: 16px;
    border-radius: 10px;
}

.career_filter_text h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    font-family: "Poppins", sans-serif;
    color: rgba(154, 29, 46, 1);
    margin-bottom: 0;
}

.career_option_filter_btn {
    text-align: center !important;
}



.career_tech {
    margin-bottom: 17px;
    /* border: 1px solid #d9d9d9; */
    padding: 11px 13px;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    background-color: #f3f3f3 !important;
}

.career_tech span {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #353535 !important;
}

.career_tech:hover {
    background-color: #a23333 !important;
    color: #E8E8E8;
    border-color: none !important;
}


.career_tech:hover span {
    color: #E8E8E8 !important;
}

.career_tech:hover .exam_ap {
    color: #fff !important;
}

.career_go_right_arrow svg {
    color: #757575;
}

.career_tech:hover .career_go_right_arrow svg {
    color: #E8E8E8 !important;
}

.career_side_bar_div button {
    padding: 7px 16px !important;
    font-family: "Poppins", sans-serif;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #3C3C3C !important;
}

.career_option_connect {
    background-color: #f4f0dd;
    padding: 50px 0;
    margin-top: 40px;
    padding-left: 89px;
    padding-right: 89px;
}

.career_connect_div {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.career_with_us h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 40px;
    color: #000;
}

.career_with_us h2 span {
    color: #a23333;
}

.career_with_us p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #4E4E4E;
    margin-top: 0px;
    margin-bottom: 0;
    width: 56%;
}

.career_mail_section {
    display: flex;
    justify-content: flex-end;
}

.career_mail_section .contact-card {
    width: 284px;
    background-color: white;
    border: none;
    border-radius: 10px;
    padding: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.career_mail_section .contact-card p {
    font-family: "Open Sans", sans-serif;
    color: #363636;
    font-weight: 400;
    font-size: 16px;
}

.career_mail_section .contact-card .icon-container {
    width: 50px;
    height: 50px;
    background-color: #7c0a0a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 15px;
}

.career_left_img_section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px !important;
}

.career_option_off_canvas {
    top: 90px !important;
    width: 60%;
    left: 21% !important;
    border-radius: 15px;
    height: max-content !important;
    transition: transform 0.5s ease-in-out !important;
}

.canvas_btn {
    justify-content: space-between;
    text-align: center;
    align-items: center;
    margin-top: 10px;
    position: relative;
}

.canvas_btn h5 {
    font-family: graphik_medium;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #000;
}

.canvas_btn button {
    border: none;
    background-color: #ffdde2;
    text-align: center;
    align-items: center;
    display: flex;
    border-radius: 100px;
    padding: 7px;
    color: #9a1d2e;
    position: absolute;
    right: 0;
    top: 0px;
}

.buttonClass {
    border: none;
    background-color: #a23333;
    ;
    /* text-align: center; */
    /* align-items: center; */
    /* display: flex; */
    border-radius: 100px;
    padding: 7px;
    color: #ffdde2;
    /* position: absolute; */
    right: 0;
    top: 0px;

}

.career_canvas_text h5 {
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    line-height: 20px;
    font-weight: 600;
    color: #9A1D2E;
    margin-bottom: 15px;
}

.career_canvas_text ul li {
    color: #575757;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 3px !important;
}

.career_canvas_text p {
    color: #575757;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 15px !important;
    width: 100%;
}

.career_canvas_text ul li a {
    color: #a23333;
    font-weight: 500;
    font-style: italic;
    text-decoration: none !important;
}

.career_canvs_location h6 {
    color: #8D8D8D !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 14px;
}

.career_canvs_location p {
    color: #383838 !important;
    font-family: "Poppins", sans-serif;
    font-weight: 400 !important;
    font-size: 15px;
}

.career_offcanvas_body {
    padding-top: 0 !important;
    padding-bottom: 35px !important;
}

.select_for_career {
    display: none;
}
.mob_hide{
    display: none;
}

.clear_filter_btn {
    display: none;
}

/* .career_left_img_section img{
    height: 44vh !important;
} */
@media (min-width: 1600px) and (max-width: 1900px) {
    .career_banner_adjust img {
        height: 114vh;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .career_option_top_text {
        width: 83%;
    }
}



@media screen and (min-width: 768px) and (max-width: 1024px) {
    .career_with_us h2 {
        font-size: 40px;
    }

    .career_option_top_text {
        width: 100%;
        top: 67px;
    }

    .carreer_text_top_banner h2 {
        font-size: 40px;
        line-height: 42px;
    }

    .career_banner_adjust img {
        height: 76vh;
        clip-path: circle(93% at 50% -22%);
    }

    .career_option_off_canvas {
        top: 170px !important;
    }

    .career_optiontabs {
        padding-left: 23px;
        padding-right: 21px;
    }

    .career_with_tabs p {
        width: 70%;
    }
}

@media only screen and (max-width: 768px) {
    .career_banner_adjust img {
        width: 100%;
        height: 68vh;
        object-fit: cover;
        clip-path: unset;
    }

    .career_option_top_text {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 100%;
        clip-path: circle(77% at 36% -55%);
        display: none;
    }

    .career_option_connect {
        padding: 23px 0;
        margin-top: 25px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .career_with_us h2 {
        font-size: 25px;
        line-height: 24px;
    }

    .career_with_us p {
        width: 100% !important;
    }

    .career_mail_section .d-flex {
        flex-direction: column;
        width: 100%;
    }

    .career_mail_section {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
    }

    .career_mail_section .contact-card {
        width: 100%;
    }

    .career_option_off_canvas {
        top: 208px !important;
        width: 98%;
        left: 1% !important;
        border-radius: 15px;
    }

    .career_canvas_text h5 {
        font-size: 20px;
    }

    .career_gapping_mobile {
        margin-top: 55px !important;
    }

    .campus_filter_for_mobile {
        display: none;
    }

    .select_for_career {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        padding-bottom: 15px;
    }
    .mob_hide{
        display: block;
    }

    .select_for_career select {
        width: 47%;
        font-size: 15px;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
    }

    .select_for_career select:focus {
        border-color: #000;
        outline: 0;
        box-shadow: none;
    }

    .read_more_read_less {
        color: #7c0a0a;
        font-weight: 600;
    }

    /* .career_canvas_text p {
        text-overflow: ellipsis;
        -webkit-line-clamp: 6;
        max-height: calc(6* 24px);
        line-height: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    } */
    .clear_filter_btn {
        display: block;
        text-align: start !important;
        margin-top: 0;
        margin-bottom: 15px;
    }

    /* .career_left_img_section img {
        height: 44vh !important;
    } */
    .filter_btn_hide_in_mobile_view {
        display: none;
    }

    .filer_accordian_content {
        transition: height 0.4s ease;
    }

    .mobile_filter_collapse {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        padding: 0px 9px 0px 6px;
    }
}