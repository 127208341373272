.courses_placement {
    display: flex;
    justify-content: space-between;
}

.courses_placement button {
    border: none;
    padding: 0;
}

.courses_placement button img {
    width: auto;
    height: auto;
}

.exam_course_btn {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 8px 3px;
    margin-top: 15px;

}

.exam_course_btn span {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000000;

}

.exam_course_btn:hover {
    background-color: #f6f2e0;
}



@media only screen and (max-width: 768px) {
    .courses_placement {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        text-align: start;
    }

    .courses_placement button {
        text-align: start;
        background-color: #fff;
    }

    .exam_program_text p {
        margin-top: 8px;
    }

    .padding_for_policy {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}
