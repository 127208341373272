.counselling_heaidng h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 68px !important;
    line-height: 90px;
    color: #a23333 !important;
    margin-top: 45px !important;
}

.counselling_img img {
    height: auto;
    width: 245px;
}

.counselling_paragraph {
    margin-bottom: 0;
}

.department_paragraph {
    width: 41%;
}












.department_horering_boxes {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 150px;
    align-items: flex-start;
    position: relative;
    margin-bottom: 47px;
    text-align: start;
    align-items: end;
    margin-left: 35px;
}

.info_box_top {
    position: relative;
    width: 325px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 34px 31px;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-bottom: 10px;
    /* Fixed space below each card */
}

.info_box_top::after {
    content: '';
    position: absolute;
    bottom: -9px;
    /* Triangle fixed to bottom */
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ccc;
    transition: border-top-color 0.3s ease;
}

.circle_department {
    position: absolute;
    bottom: -47px;
    /* Fixed circle alignment */
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 12px;
    background-color: #ccc;
    border-radius: 50%;
    z-index: 1;
}



.info_box_top:hover {
    background-color: #9a1d2e;
    color: #fff;
}

.info_box_top p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

.info_box_top:hover p {
    color: #fff;
}

.info_box_top:hover .circle_department {
    background-color: #9a1d2e;
}

.info_box_top:hover::after {
    border-top-color: #9a1d2e;
}



/* For the second set of boxes */
.department_horering_boxes_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 150px;
    align-items: flex-start;
    position: relative;
    margin-bottom: 0px;
    text-align: start;
    align-items: flex-start;
    margin-left: 267px;
}

.circle_bottom {
    position: absolute;
    top: -46px;
    left: 51%;
    transform: translateX(-50%);
    width: 12px;
    height: 12px;
    background-color: #ccc;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    z-index: 1;
}

.info_box_bottom {
    position: relative;
    width: 325px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 1.5rem;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-bottom: -12px;
}

.info_box_bottom::after {
    content: '';
    position: absolute;
    top: -9px;
    left: 45%;
    transform: translateX(-52%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ccc;
    transition: border-top-color 0.3s ease;
    rotate: 180deg;
}

.info_box_bottom:hover {
    background-color: #9a1d2e;
    /* Red background on hover */
    color: #fff;
    /* White text on hover */
}

.info_box_bottom p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

.info_box_bottom:hover p {
    color: #fff;
}

.info_box_bottom:hover .circle_bottom {
    background-color: #9a1d2e;
    /* Red circle on hover */
}

.info_box_bottom:hover::after {
    border-top-color: #9a1d2e;
    /* Red triangle on hover */
}


/* Horizontal Scrollable Card Container */
.dotted-line {
    position: absolute;
    top: 52%;
    /* Vertically centered */
    left: 0;
    border-top: 2px dashed #000;
    height: 2px;
    z-index: 0;
    pointer-events: none;
    width: 100%;
    /* Default width */
}


.card_overflow {
    position: relative;
    padding-bottom: 37px;
    overflow-x: auto;
    cursor: grab;
    position: relative;
}

.card_overflow::-webkit-scrollbar {
    display: none;
}


.card_overflow::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.card_overflow::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.counselling_department_gapping {
    background-color: #fffcec;
    padding-top: 45px;
    padding-bottom: 30px;
}



















.department_hovering_col {
    padding-left: 0;
    padding-right: 0;
}

.department_sucees_section {
    text-align: start;
}

.department_sucees_section h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
    color: rgba(0, 0, 0, 1);
}

.department_sucees_section h3 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
    color: rgb(154 29 46);
}

.department_sucees_section p {
    color: rgba(141, 141, 141, 1);
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    max-height: calc(93px);
    width: 80%;
}

.department_offer h5 {
    color: rgba(0, 0, 0, 1);
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    width: 53%;
    line-height: 26px;
    margin-bottom: 35px;
    height: 50px;
}

.department_offer p {
    color: rgba(141, 141, 141, 1);
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 55px;
    margin-bottom: 30px;
}

.counselling_rr_card_bg {
    background-color: #fff;
    padding: 25px;
    border-radius: 15px;
    position: relative;
    /* Ensures child elements are positioned relative to this card */
    transition: all 0.3s ease-in-out;
    /* Smooth transition for hover effects */
    overflow: hidden;
    /* Prevents content overflow */
}

.counselling_rr_card_bg:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    /* Hover shadow effect for the card */
}

.department_offer {
    position: relative;
    z-index: 1;
    /* Ensures the text stays above the background */
}

.department_offer .card-title {
    margin-bottom: 15px;
    position: relative;
    /* Keeps the title fixed */
    z-index: 2;
    /* Ensure it remains above other elements */
    transition: none;
    /* Prevents the title from moving */
}

.department_offer .campus-content {
    position: relative;
    z-index: 1;
    transition: transform 0.3s ease-in-out;
    /* Smooth slide effect for the paragraph */
}

.counselling_rr_card_bg:hover .department_offer .campus-content {
    transform: translateY(-20%);
    /* Slide the paragraph upwards */
}

.read_more_button {
    position: absolute;
    bottom: -50px;
    /* Hidden below the card initially */
    left: 22%;
    /* Center the button horizontally */
    transform: translateX(-50%);
    border: none;
    background-color: #a23333;
    /* Button background color */
    padding: 6px 25px;
    border-radius: 24px;
    color: #fff;
    /* Button text color */
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    opacity: 0;
    /* Initially hidden */
    pointer-events: none;
    /* Disable interaction initially */
    transition: all 0.3s ease-in-out;
    /* Smooth fade and position animation */
}

.counselling_rr_card_bg:hover .read_more_button {
    bottom: 20px;
    /* Move into visible position */
    opacity: 1;
    /* Make visible */
    pointer-events: auto;
    /* Enable interaction */
}

.deartment_placement_hovering:hover {
    background: #9a1d2e;
}




.early_circular_image2{
    width: 500px ;
    height: 500px !important;
    object-fit: cover;
    padding: 5%;
    max-width: 514px;
    height: auto; /* Maintain aspect ratio */
    border-radius: 50%;
    overflow: hidden; 
}


.deartment_placement_hovering {
    background: #ebebeb;
    border-radius: 15px;
    margin-right: 14px;
    padding: 16px 2px 2px 2px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .counselling_heaidng h2 {
        font-size: 40px !important;
        line-height: 48px;
    }

    .department_sucees_section h2 {
        font-size: 40px;
        line-height: 35px;
    }

    .department_sucees_section h3 {
        font-size: 40px;
        line-height: 43px;
    }

    .deartment_placement_hovering {
        margin-right: 1px;
    }

    .counselling_rr_card_bg:hover .department_offer .campus-content {
        transform: translateY(-8%);
    }

    .read_more_button {
        bottom: -50px;
        left: 30%;
        transform: translateX(-50%);
        padding: 6px 25px;
        font-weight: 600;
    }

    .department_offer h5 {
        width: 95%;
    }

    .department_mobile {
        padding-right: 0;
    }

    .department_padding {
        margin-left: 0 !important;
        padding-right: 0 !important;
    }

    .department_offer h5 {
        height: 20px;
    }

    .department_offer p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }
}

@media only screen and (max-width: 768px) {
    .counselling_heaidng h2 {
        font-family: graphik_bold;
        font-weight: 700;
        font-size: 25px !important;
        line-height: 37px;
        margin-top: 132px !important;
    }
    .early_circular_image2{
        width: 350px !important;
        height: 350px !important;
        object-fit: cover;
    }
    .counselling_heaidng br {
        display: none;
    }

    .counselling_img {
        flex-direction: column;
    }

    .counselling_img img {
        height: auto;
        width: 150px;
    }

    .counselling_paragraph {
        margin-bottom: 0;
        width: 95%;
    }

    .department_paragraph {
        width: 100% !important;
        margin-bottom: 0;
    }

    .department_horering_boxes {
        align-items: end;
        margin-left: 15px;
    }

    .info_box_top {
        padding: 8px 17px;
        border-radius: 8px;
        margin-bottom: 10px;
    }

    .dotted-line {
        top: 49%;
    }

    .info_box_bottom {
        padding: 11px 17px;
    }

    .info_box_top p {
        font-weight: 300;
    }

    .info_box_bottom p {
        font-weight: 300;
    }

    .process_step_gapping {
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .department_sucees_section h2 {
        font-size: 25px;
        line-height: 18px;
    }

    .department_sucees_section h3 {
        font-size: 24px;
        line-height: 24px;
    }

    .info_gapping_section {
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 20px;
    }

    .deartment_placement_hovering {
        margin-right: 0px;
        padding: 16px 2px 2px 2px;
    }

    .department_padding {
        padding-right: 0;
    }

    .department_padding .d-flex {
        padding-right: 0;
    }

    .department_padding .department_gap_mobile {
        gap: 20px;
    }


}
