/* .news_lsiting_slide {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 12px;
    padding: 20px 30px;
    overflow: auto;
    max-height: 820px;
} */
.eligible_tag_for_news.selected {
    background-color: #9a1d2e;
    /* Highlight color */
    color: #fff !important;
    /* Ensures the text is white */
    border-color: #9a1d2e;
    /* Optional: Makes border match background */
}

.eligible_tag_for_news.selected .tag-name {
    color: #fff !important;
    /* Ensure the text color is white */
}

.news_lsiting_slide::-webkit-scrollbar {
    display: none;
}

.news_banner_div h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 40px;
    color: #000;

}

.news_banner_div h2 span {
    color: #9a1d2e !important;
}

.news_banner_div p {
    color: #575757;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    line-height: 24px;
    font-size: 16px;
}

.news_banner_div_img {
    width: 100%;
}

.news_banner_div_img img {
    height: 44vh;
    width: 100% !important;
    object-fit: cover;
    border-radius: 30px;
}

.news_banner_main_div {
    margin-bottom: 33px;
}

.eligible_tag_for_news:hover {
    background-color: #9a1d2e;
    color: #fff;
    cursor: pointer;
}

.eligible_tag_for_news:hover .tag-name {
    color: #fff !important;
}

.eligible_tag_for_news:hover span {
    color: #fff !important;
}

.news_info_breadcrumb {
    padding: 4px 20px;
}

.news_info_breadcrumb ol li a {
    text-decoration: none;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
}

.news_info_breadcrumb ol li a span {
    color: #9a1d2e !important;
}

.sticky_info_for {
    position: fixed;
    top: 210px;
    /* z-index: -1; */
}


.pegination_for_news .pagination .page-item .page-link {
    border-radius: 50%;
    margin: 0 5px;
    color: black;
    padding: 7px 14px;
    background-color: #fff;
}

.pegination_for_news .pagination .page-item.active .page-link {
    background-color: #9a1d2e;
    /* Active background color */
    color: #fff;
    border-color: unset !important;
}

.pegination_for_news .pagination .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff !important;
    border: none;
}

.pegination_for_news .pagination .page-item .page-link:hover {
    background-color: #9a1d2e !important;
    /* Change on hover */
    color: #fff !important;
}

.pegination_for_news .pagination .page-item .page-link:focus {
    box-shadow: none !important;
    background-color: #fff !important;
    color: #000 !important;
}

.pegination_for_news ul .next a {
    padding: 8px 13px !important;
    border-radius: 89px !important;
}

.pegination_for_news ul .next a:hover {
    color: #fff !important;
}



/* /////////// Deatils page css///////////////////// */
.details_border {
    width: 100% !important;
    gap: 0 !important;
}

.details_border h6 {
    border-bottom: 3px solid #dbdbdb;
    padding-bottom: 18px;
    font-size: 22px !important;
}

.details_border li {
    border-bottom: none !important;
    padding-bottom: 5px;
    padding-top: 4px;
}

.details_step_gapping {
    padding-left: 63px;
    padding-right: 63px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.get_details_content {
    overflow: auto;
    max-height: 460px;
    /* padding: 20px; */
    padding-top: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-bottom: 0;
}

.full_width {
    width: 100%;
}

.details_founder {
    border-radius: 15px !important;
}

.details_right_section_text {
    padding: 0px 10px;
}

.details_right_section_text h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 23px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 0 !important;
}

.details_right_section_text p {
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;
    margin-top: 5px !important;
}

.details_banner_img {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.details_banner_img img {
    height: 359px;
    width: 84% !important;
    object-fit: cover;
    border-radius: 30px;
    text-align: left;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
}

.details_banner_heading h4 {
    font-weight: 600;
    font-size: 43px;
    line-height: 60px;
    font-family: graphik_bold;
    color: #454545;
}

.details_banner_heading h4 span {
    color: #9a1d2e !important;
}

.details_meta {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 60%;
}

.details_banner_heading hr {
    border-top: 1px solid #170505;
    width: 22%;
    margin: 20px 0;
}



.details_meta p {
    margin: 0;
    font-weight: 300;
    font-size: 20px;
    line-height: 29px;
    font-family: "Poppins", sans-serif;
}

.details_meta span {
    font-weight: 500;
}




.details_banner_section {
    background-color: #fffcec;
    padding: 23px 40px;
    border-radius: 20px;
}

.news_lsiting_slide a {
    text-decoration: none;
}


.blog_detail_pannel {
    display: flex;
    flex-direction: row;
}

.left_side,
.right_side {
    position: sticky;
    top: 110px;
    align-self: start;
}

.admission_process_tabs li a.active span {
    color: #9a1d2e !important;
    /* Change the color to red when the link is active */
    font-weight: 700;
    /* Optional, make the active link bold */
}

.details_border li a {
    text-decoration: none !important;
    -webkit-line-clamp: 1;
    max-height: calc(1* 24px);
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.sticky_info_for_details {
    position: fixed;
    top: 100px;
    width: 22%;
}


.sticy_name {
    position: sticky;
    top: 0;
    z-index: 1000;
}

.details_border li a span {
    font-weight: 400 !important;
    /* color: grey; */
    color: #8D8D8D !important;
    font-size: 16px !important;
}

.deatails_padding {
    padding: 10px 0px !important;
}

.new_deatils {
    padding: 20px 15px 0px 18px !important;
}

.new_deatils p {
    max-height: calc(44px) !important;
}

.details_page_inner_html ul li span {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    color: #8D8D8D !important;
}

.expam_program_new_card {
    display: flex;
    width: 100%;
}

.expam_program_new_card {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* 5 columns */
    gap: 16px;
    /* Space between cards */
}

.deatils_row_card {
    /* Ensure cards are styled properly */
    display: flex;
    flex-direction: column;
    /* Optional border */
}

.details_card_content {
    height: 144px;
}

.news_image_for_mobile img {
    height: 215px;
    object-fit: cover;
}

.event_news_card_drop {
    height: 380px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .news_banner_div h2 {
        font-size: 40px;
    }

    .news_card_tabs h5 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .news_card_tabs h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .news_card_tabs p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
    }

    .news_card_news_tabs {
        padding-right: 0 !important;
    }

    .next_deatils_tabs h4 {
        font-size: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 47px;
    }

    .details_meta {
        width: 89%;
    }

    .next_deatils_gapping {
        padding-left: 25px;
        padding-right: 25px;
    }

    .details_card_content h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .details_card_content h5 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .details_card_content p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .new_details_title h1 {
        font-size: 27px;
    }

    .details_right_section_text h2 {
        font-size: 18px;
        line-height: 25px;
    }

}

@media only screen and (max-width: 768px) {
    .news_banner_div {
        margin-top: 90px;
    }

    .news_banner_div h2 {
        font-size: 25px;
        line-height: 24px;
    }

    .news_banner_div_img img {
        border-radius: 15px;
    }

    .sticky_info_for {
        position: unset;
        top: 100px;
    }

    .new_mobile_div {
        display: flex;
        flex-direction: column-reverse;
        gap: 15px;
    }

    .news_event_card {
        margin-right: 0 !important;
    }

    .sticky_info_for_details {
        position: unset;
        top: 100px;
        width: 100%;
    }

    .details_step_gapping {
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 10px;
        margin-bottom: 40px;
    }

    .admission_process_tabs {
        display: none;
    }

    .news_banner_main_div {
        margin-bottom: 33px;
        /* margin-top: 140px; */
    }

    .details_banner_heading h4 {
        font-size: 25px;
        line-height: 29px;
    }

    .details_meta {
        margin-top: 1px;
        width: 101%;
    }

    .details_banner_heading hr {
        border-top: 1px solid #170505;
        width: 93%;
        margin: 20px 0;
    }

    .details_banner_img img {
        height: 256px;
        width: 99% !important;
        object-fit: cover;
        border-radius: 15px;
        ;
    }

    .details_banner_img {
        margin-top: 20px;
        justify-content: flex-end;
    }

    .details_banner_section {
        background-color: unset;
        padding: 21px 0px;
        border-radius: 20px;
        margin-top: 140px;
    }

    .details_meta p {
        font-size: 18px;
        line-height: 29px;
    }

    .details_right_section_text h2 {
        font-size: 19px;
        line-height: 30px;
    }

    .details_right_section_text {
        padding: 0px 10px;
        text-align: start;
    }

    .deatils_para_section .home_founder_sub_image {
        padding-left: 0 !important;
    }

    .deatils_para_section p {
        width: 100% !important;
    }

    .pegination_for_news .pagination .page-item .page-link {
        margin: 0 5px;
        padding: 5px 12px !important;
    }

    .pegination_for_news .pagination {
        margin-top: 0 !important;
    }

    .expam_program_new_card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }

    .deatils_mobile_event {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        line-height: 24px;
        /* Ensure consistent line height */
        max-height: 24px;
        /* Equal to one line height */
    }

    .details_card_content {
        height: 170px;
    }
    .new_details_title{
        margin-top: 10px;
    }
}
