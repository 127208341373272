@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');


.admission_info_heading h2 {
    font-family: graphik_light;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 32px;
}

.admission_info_heading h1 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 36px;
    line-height: 55px;
    color: #a23333;
    margin-bottom: 0;
}

.info_paragraph_growth p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* margin-bottom: 0; */
    color: #575757;
}

.info_main_heading {
    margin-top: 6rem;
}

.info_main_heading h1 {
    font-family: graphik_bold;
    font-size: 165px;
    line-height: 100px;
}

.info_all_nav {
    background-color: #f4f4f4;
    border-radius: 100px;
    padding: 5px 3px
}

.info_breadcrumb {
    padding-left: 15px;
    margin-bottom: 2px;
}

.info_breadcrumb li {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: rgba(50, 50, 50, 1) !important;
}

.info_breadcrumb li a {
    color: rgba(50, 50, 50, 1);
    text-decoration: none;
}

.info_prcoess_nav li {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: rgba(50, 50, 50, 1) !important;
}

.info_process_img {
    margin-top: 30px;
}

.info_process_img img {
    height: 60vh;
    object-fit: cover;
    border-radius: 10px;
}

.admission_info_text {
    width: 88%;
}

.admission_info_text h2 {
    font-family: graphik_bold;
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 700;
}

.admission_info_text h2 span {
    color: #a23333;
}

.info_left_section {
    padding-left: 4.5rem;
}

.info_right_section {
    padding-right: 4.5rem;
}



.info_text h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: rgba(87, 87, 87, 1);
}

.info_text h3 {
    font-family: graphik_bold;
    font-size: 50px;
    line-height: 65px;
}

.info_text p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: rgba(87, 87, 87, 1);
}

.info_grade_img {
    margin-top: 3rem;
}

.grade_six {
    margin-top: 3rem !important;
}

.info_grade_one {
    color: #a23333;
}

.info_grade_img img {
    filter: grayscale(1);
}


/* AdmissionInfoSection.css */

.admission-section {
    position: relative;
}

.admission-text-box {
    top: 53%;
    right: 0;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.9);
    /* border-radius: 15px; */
    padding: 45px;
    max-width: 30%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 165px;
    border-bottom-left-radius: 165px;
    padding-right: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.admission-text-box p {
    font-size: 1rem;
    color: #333;
}

.virtual_school_tour_wrapper {
    width: 100%;
    margin-top: 45px;
    margin-bottom: 30px;
}

.virtual_school_tour_wrapper .container-fluid {
    padding-left: 0;
}

.virtual_tour_heading {
    height: 100%;
}

.virtual_tour_heading h2 {
    font-family: graphik_bold;
    font-size: 50px;
    margin-bottom: 10px;
}

.virtual_tour_heading h2 span {
    color: #a23333;
}

.virtual_tour_heading p {
    font-size: 18px;
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    color: rgba(141, 141, 141, 1);
}

.virtual_tour_heading h5 {
    font-size: 20px;
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-bottom: 12px;
}

.virtual_tour_heading h5 span {
    color: #a23333;
}

.contact_detail {
    display: flex;
    align-items: center;
    background-color: #f5f3e8;
    padding: 7px 9px;
    border-radius: 38px;
    margin-bottom: 10px;
    width: max-content;
}

.info_icon_container {
    background-color: #a0293f;
    padding: 6px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.info_icon_container svg {
    color: white;
    font-size: 13px;
}

.contact_detail span {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    text-decoration: underline;
    color: rgba(54, 54, 54, 1);
}

.info_image_container video {
    height: 47vh !important;
    border-radius: 11px !important;
}

/* .info_image_container {
    height: 40vh;
    border-top-right-radius: 125px;
    border-bottom-right-radius: 125px;
    overflow: hidden; 
} */

/* .info_image_container img,
.info_image_container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */



.gallery_img_div {
    width: 100%;
}

.gallery_img_div img {
    width: 100%;
}

.gallery-slide {
    padding: 0 10px;
}

.explore_slider_main_div .slick-list {
    margin: 0 -10px;
}

.gallery_main_section {
    margin-bottom: 40px;
}

.gallery_main_section span {
    font-size: 25px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: rgba(74, 74, 74, 1);
    padding-left: 20px;
    padding-right: 20px;
}

.custom-border {
    flex-grow: 1;
    border-bottom: 3px solid #f0f0f0;
}


/* ////////////////////////////////////////////////////////////////////
Regestration form css here */


.redgestration_form h2 {
    font-family: graphik_medium;
    font-size: 50px;
    font-weight: 600;
}

.redgestration_form h2 span {
    font-family: graphik_bold;
    color: #a0293f;
    font-weight: 700;
}

.redgestration_form h6 {
    font-size: 19px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.redgestration_form h6 span {
    color: rgba(154, 29, 46, 1);
}

.redgestration_form p {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    color: rgba(87, 87, 87, 1);
    margin-bottom: 35px;
}

.form_gapping_section {
    padding-left: 37px;
    padding-right: 37px;
    padding-top: 50px;
    padding-bottom: 0px;
}

.regestration_form_input input:focus {
    color: rgba(140, 140, 140, 1);
    border-color: unset;
    outline: none;
    box-shadow: none;
}

.regestration_form_input input::placeholder {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: rgba(140, 140, 140, 1);
}

.regestration_form_input select:focus {
    color: rgba(140, 140, 140, 1);
    border-color: unset;
    outline: none;
    box-shadow: none;
}

.regestration_form_input select {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: rgba(140, 140, 140, 1);
}

.regestration_form_input input:focus {
    color: rgba(140, 140, 140, 1);
    border-color: unset;
    outline: none;
    box-shadow: none;
}

.regestration_form_input .form-label {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: rgba(140, 140, 140, 1);
    margin-bottom: 4px;
}

.regestration_form_input input {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: rgba(140, 140, 140, 1);
}

.form_heading_container {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    padding: 10px 16px;
    border-radius: 8px;
    margin-bottom: 20px !important;
}

.form_heading_container .heading-text {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    margin: 0;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
}

.regestration_form_input {
    position: relative;
    text-align: start;
}

.custom-placeholder {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: rgba(140, 140, 140, 1);
    pointer-events: none;
}

.red-asterisk {
    color: #a23333;
}

.enter_na_form {
    align-items: center;
    background-color: #fef5f6;
    padding: 10px 16px;
    border-radius: 8px;
    margin-bottom: 20px !important;
    text-align: center;
    display: flex;
    justify-content: center;
}

.enter_na_form h5 {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    color: rgba(154, 29, 46, 1);

}

.font_reduce_size_program {
    text-align: start !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
}

.font_reduce_size_program h5 {
    font-size: 14px !important;
}

.cool_cat_color {
    color: #9a1d2e;
}

.regestration_note {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: start;
    margin-top: 6px;
    color: rgba(154, 29, 46, 1);
}

#kcApplication {
    accent-color: rgba(154, 29, 46, 1);
    width: 15px;
}

.regestration_kc_radio {
    border: 1px solid #dfdfdf;
    border-radius: 23px;
    padding: 3px 12px;
    display: flex;
    gap: 10px;
}

.regestration_applied_heading {
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(87, 87, 87, 1);
}

.regestration_applied_yes {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(87, 87, 87, 1);
}

.mention_name_section {
    text-align: start;
}

.mention_name_section h5 {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    margin: 0;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
}

.regestration_program {
    background-color: #f4f4f4;
    border-radius: 11px;
    margin-top: 10px;
    padding: 15px;
}

.regestration_program .program {
    margin-top: 0px;

}

.regestration_program .program input {
    background-color: rgba(255, 255, 255, 1);

}

.regestration_text_area {
    flex-direction: column;
    text-align: start;
    align-items: start;
}

.regestration_text_area label {
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(87, 87, 87, 1);
}

.regestration_text_area textarea {
    margin-top: 10px;
    width: 100%;
    min-height: 100px;
    /* Set height as needed */
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #555;
    resize: none;
    outline: none;
    transition: border-color 0.3s;
}

.regestration_text_area textarea::placeholder {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: rgba(140, 140, 140, 1);
}

.guardian_details {
    margin-top: 3px;
}

.eligible_tag {
    align-items: center;
    padding: 5px 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-family: Arial, sans-serif;
    color: #555;
    font-size: 14px;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 4px;
}

.eligible_tag .tag-name {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: rgba(154, 29, 46, 1);
    margin-bottom: 0;
}

.eligible_tag:not(:last-child) {
    margin-right: 0px;
}

.eligible_main_div {
    gap: 10px;
}

.eligible_tag span {
    color: rgba(140, 140, 140, 1);
    font-family: "Poppins", sans-serif;
    font-size: 12px;
}

.form_choice_language {
    text-align: start;
    margin-top: 10px;
}

.form_choice_language h5 {
    font-family: "Poppins", sans-serif;
    color: rgba(87, 87, 87, 1);
    font-weight: 400;
    font-size: 16px;

}

.online_program_section {
    text-align: start;
    align-items: flex-start;
}

.online_program_section h5 {
    font-family: "Poppins", sans-serif;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 16px;
}

.online_program_section p {
    font-family: "Poppins", sans-serif;
    color: rgba(87, 87, 87, 1);
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 0;
}

.form_download_btn {
    display: flex;
    gap: 10px;
}

.form_download_btn button {
    border: navajowhite;
    background-color: #a23333;
    border-radius: 23px;
    padding: 7px 20px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.form_file_upload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.form_file_upload label {
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 1px solid #a23333;
    border-radius: 32px;
    color: #a23333;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.form_questionnaire {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    background-color: #fef5f6;
    padding: 8px 20px;
    border-radius: 35px;
}

.form_questionnaire h5 {
    font-family: "Poppins", sans-serif;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
}

.form_questionnaire svg {
    color: #a23333;
}


.form_submiting_div {
    display: flex;
    text-align: start;
    align-items: flex-start;
    /* width: 100%; */
}

.form_submiting_div p {
    font-family: "Poppins", sans-serif;
    color: rgba(87, 87, 87, 1);
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
}

.form_subbmit_btn {
    /* display: flex
; */
    margin-top: 15px;
    /* width: 100%; */
    text-align: start;
}

.form_subbmit_btn button {
    border: none;
    background-color: #a23333;
    padding: 6px 25px;
    border-radius: 24px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.all_card_hight {
    height: 333px;
}

.sticky_info {
    position: fixed;
    top: 80px;
    width: 93%;
    overflow: hidden;
    left: 53px;
    margin: auto;
    z-index: +1;
}

.active-link {
    color: red !important;
    /* Change the link color to red when active */
    font-weight: bold;
    /* Optional: make the active link bold */
}

.custom_input_wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
}

.custom_country_code {
    border: none;
    background: transparent;
    outline: none;
    margin-right: 8px;
    font-size: 14px;
    appearance: none;
    color: #000 !important;
}

.custom-phone-input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 14px;
}

.admission_faq_bakground {
    background-color: #faf8f0;
    padding-bottom: 16px;
}

.rr_main_div {
    background: #ebebeb;
    border-radius: 15px;
    margin-right: 14px;
    padding: 16px 2px 2px 2px;
}

.rr_card_bg {
    background-color: #fff;
    padding: 25px;
    border-radius: 15px;
}

.rr_hovering_section {
    overflow: hidden;
    border-radius: 15px;
}

.rr_hovering_section img {
    width: 100%;
    border-radius: 20px;
}

.rr_card_bg:hover .rr_hovering_text {
    top: 0%;
    background: #ffffff;
    animation: slideUp 0.8s ease-in-out forwards;
    color: black;
}

.rr_hovering_section:hover .rr_hovering_text {
    top: 0%;
    background: #ffffff;
    animation: slideUp 0.8s ease-in-out forwards;
    color: black;
}

.rr_hovering_text {
    z-index: 99;
    top: 100%;
    width: 100%;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 20px;
}

.rr_hovering_text p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    color: #8D8D8D;
}

.rr_hovering_section:hover .academic_middle_slider {
    top: 0%;
    background: #9a1d2ebd;
    animation: slideUp 0.8s ease-in-out forwards;
}

.rr_card_bg h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 25px;
    color: #000;
    line-height: 45px;
}

.rr_card_bg h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #000;
    line-height: 45px;
    margin-bottom: 16px;
}

.rr_card_bg h6 span {
    color: #a23333;
}

.rr_hovering_btn {
    margin-top: 0;
}

.info_gapping_section {
    padding-left: 55px;
    padding-right: 55px;
    margin-top: 55px;
    margin-bottom: 55px;
}

.admission_info_campus {
    align-items: center;
    display: flex;
}

.admission_info_campus {
    font-family: graphik_bold;
    color: #e5e5e5;
    font-weight: 700;
    font-size: 122px;
    transform: rotate(180deg);
    transform-origin: center center;
    white-space: nowrap;
    writing-mode: vertical-rl;
}

.admission_global_text p {
    color: #575757 !important;
    width: 76% !important;
}

.admission_stats_content {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.admission_stats_content h3 {
    font-family: graphik_medium;
    color: #000000;
    font-weight: 600;
    margin-bottom: 0;

}

.admission_stats_content p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 13px;
    color: #575757;
    width: 76%;
    margin-bottom: 0;
}

.admission_stats_card {
    background-color: #faf8ee;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
    transition: all 0.3s ease;
    cursor: pointer;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.admission_stats_card:hover {
    background-color: #fff;
}


.admission_stats_card:hover .admission_stats_content h3 {
    color: #a32532;
}





/* ////////////////////////// this css only for admission top animation  */
.admission_hover_section {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 15px;
}

.admission_background_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;
}

.admsiion_section_active {
    position: relative;
    height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border-right: 2px solid white;
    z-index: 1;
}

.admsiion_section_active:last-child {
    border-right: none;
}

.admission_section_title {
    position: absolute;
    top: 50px;
    left: 54%;
    transform: translateX(-40%);
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    z-index: 2;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
    text-align: start;
}

.admission_overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
    z-index: 1;
}

.admsiion_section_active.active .admission_overlay {
    transform: translateY(0);
    opacity: 1;
}

.admission_section_content {
    color: white;
    text-align: center;
    padding: 45px;
    padding-top: 100px;
}

.admission_section_content p {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    text-align: start;
    line-height: 30px;
    width: 84%;
}

.admission_read_more {
    text-decoration: none;
    margin-top: 10px;
    text-align: start;
    width: 100%;
    align-items: flex-start;
    display: flex;
    margin-top: 80px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    text-align: start;
    line-height: 30px;
}

.admission_read_more span {
    margin-left: 5px;
}

/* ////////////////////////// here is end css only for admission top animation  */



.admission_process_tabs .nav-link {
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    border-radius: unset;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.admission_process_tabs .nav-link .tab-step {
    margin-right: 30px;
    font-size: 18px;
    color: #b5b5b5;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: rgba(199, 199, 199, 1);
}

.admission_process_tabs h6 {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #000;
}

.admission_process_tabs li {
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 2px solid #eaeaea;
    padding-bottom: 14px;
    padding-top: 2px;
    cursor: pointer;
}

.admission_process_tabs {
    display: flex;
    gap: 15px;
    width: 80%;
    text-align: start;
}

.admission_process_tabs li span {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
}

.admission_step_number {
    font-size: 18px;
    color: #b5b5b5 !important;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin-bottom: 0;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.step-item.active span {
    color: #a32532 !important;
}



.get_in_touch_content {
    overflow: auto;
    max-height: 375px;
    padding: 20px;
    padding-top: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-bottom: 0;
}

.get_in_touch_content::-webkit-scrollbar {
    display: none;
}

.enquiry-section {
    position: relative;
    border-left: 3px solid #ccc;
    padding-left: 15px;
}

.for_circle::before {
    content: attr(data-step);
    /* Dynamically sets content */
    position: absolute;
    /* top: 1px; */
    left: -16px;
    background-color: #a32532;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 1;
}

/* .for_circle::before {
    content: "01";
    position: absolute;
    top: 1px;
    left: -16px;
    background-color: #a32532;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
} */

.for_circle2::before {
    content: "02";
    position: absolute;
    /* top: 1px; */
    left: -16px;
    background-color: #a32532;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.for_circle3::before {
    content: "03";
    position: absolute;
    /* top: 1px; */
    left: -16px;
    background-color: #a32532;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.for_circle4::before {
    content: "04";
    position: absolute;
    /* top: 1px; */
    left: -16px;
    background-color: #a32532;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-top: 1px;
}

.for_circle5::before {
    content: "05";
    position: absolute;
    /* top: 1px; */
    left: -16px;
    background-color: #a32532;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.process_step_content {
    margin-top: 0px;
}

.process_right_gap {
    margin-left: 20px;
}

.process_year_content {
    padding-left: 0 !important;
    padding-top: 40px !important;
}

.process_year_content h6 {
    font-family: graphik_medium;
    font-weight: 600;
    font-size: 30px;
    color: rgba(154, 29, 46, 1);
    line-height: 30px;
}

.process_year_content h5 {
    font-family: graphik_medium;
    font-weight: 600;
    font-size: 30px;
    color: rgba(0, 0, 0, 1);
    line-height: 55px;
    margin-bottom: 0;
}

.process_year_content h4 {
    font-family: graphik_light;
    color: rgba(16, 16, 16, 1);
    font-weight: 600;
    font-size: 21px;
    width: 75%;
    line-height: 35px;
}

.process_year_content p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
    color: #8D8D8D;
}

.process_step_btn {
    margin-top: 20px !important;
}

.process_step_btn button {
    padding: 7px 27px !important;
    font-size: 15px !important;
}

.process_step_gapping {
    padding-left: 86px;
    padding-right: 86px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.final_form_submited {
    margin-top: 10px;
}

.final_form_submited span {
    margin-left: 2px;
}

.add_more_btn button {
    position: absolute;
    top: -15px;
    right: 21px;
    z-index: wi;
    width: 10px;
    border: none;
    background-color: unset;

}

.add_more_btn {
    padding: 26px !important;
}

#radio_btn {
    accent-color: #9a1d2e !important;
}

.prodecc_like_FAQ {
    display: none;
}

.admission_limit_line li span {
    text-overflow: ellipsis;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    max-height: calc(1* 24px);
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .sticky_info {
        position: fixed;
        top: 80px;
        width: 92%;
        overflow: hidden;
        left: 53px;
        margin: auto;
        z-index: 9999;
    }

    .admission_read_more {
        text-decoration: none;
        margin-top: 10px;
        text-align: start;
        width: 100%;
        align-items: flex-start;
        display: flex;
        margin-top: 30px;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        text-align: start;
        line-height: 30px;
    }

    .admission_section_content p {
        width: 96%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .admission_section_content p {
        width: 100%;
        line-height: 25px;

    }

    .admission_section_content {
        padding-top: 100px;
        padding-left: 35px !important;
        padding-right: 12px !important;
    }

    .admsiion_section_active {
        height: 375px;
    }

    .admission_read_more {
        margin-top: 20px;
    }

    .process_step_gapping {
        padding-left: 15px;
        padding-right: 15px;
    }

    .admission_process_tabs {
        width: 100%;
    }

    .admission_process_tabs li {
        gap: 14px;
        padding-bottom: 8px;
        padding-top: 0px;
    }

    .get_in_touch_content {
        max-height: 287px;
    }

    .admission_info_campus {
        font-size: 95px;
    }

    .info_gapping_section {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .rr_hovering_text p {
        font-weight: 300;
        font-size: 15px;
        line-height: 21px;
    }

    .virtual_tour_heading h2 {
        font-size: 40px;
    }

    .redgestration_form h2 {
        font-size: 40px;
    }

    .admission_process_tabs li span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}

@media only screen and (max-width: 768px) {
    .redgestration_form h2 {
        font-size: 25px;
    }

    .redgestration_form h6 {
        font-size: 16px;
    }

    .form_gapping_section {
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 100px;
    }

    .redgestration_form p {
        font-size: 16px;
    }

    .regestration_div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        gap: 8px;
    }

    .regestration_div label {
        text-align: start;
        /* align-items: flex-start; */
    }

    .regestration_program {
        background-color: #f4f4f4;
        border-radius: 11px;
        margin-top: 10px;
        padding: 14px 2px;
        display: flex;
        gap: 10px;
        margin-left: 3px;
        margin-right: 3px;
    }

    .eligible_tag .tag-name {
        font-weight: 400;
        font-size: 14px;
    }

    .form_subbmit_btn button {
        font-size: 15px;
    }

    .form_file_upload label {
        font-size: 15px;
    }

    .form_heading_container .heading-text {
        text-align: start;
    }

    .enter_na_form h5 {
        text-align: start;
    }

    /* /////////////////////////////////////  admission info css*/
    .admission_info_mobile {
        margin-top: 100px;
    }

    .admission_hover_section {
        margin-top: 111px;
    }

    .admsiion_section_active {
        border-bottom: 2px solid #fff;
    }

    .admission_stats_card {
        width: 110px;
        height: 110px;
        margin: 9px;
    }

    .admission_stats_content h3 {
        font-size: 15px;
    }

    .career_connect_div {
        padding-bottom: 15px;
    }

    .process_year_content h6 {
        font-size: 22px;
        line-height: 15px;
        font-weight: 400;
    }

    .process_right_gap {
        margin-left: 0px;
    }

    .process_year_content h5 {
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
    }

    .process_year_content h4 {
        font-size: 21px;
        width: 100%;
    }

    .get_in_touch_content {
        max-height: 418px;
    }

    .extra-content {
        margin-top: 40px;
    }

    .admission_info_campus {
        display: none;
    }

    .shaping_tomorrow {
        padding-top: 0;
        padding-bottom: 0;
    }

    .age_group_mobile {
        gap: 20px;
    }

    .rr_main_div {
        margin-right: 0px;
        padding: 16px 2px 2px 2px;
    }

    .age_group_m_div {
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
    }

    .rr_card_bg h5 {
        font-size: 20px;
        line-height: 34px;
    }

    .rr_card_bg h6 {
        font-size: 18px;
        line-height: 45px;
    }

    .virtual_tour_heading h2 {
        font-size: 25px;
        margin-bottom: 2px;
    }

    .virtual_tour_heading h5 {
        font-size: 16px;
        margin-bottom: 0;
        margin-bottom: 14px;
        margin-top: 4px;
    }

    .react_us_mobile {
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .info_image_container {
        margin-top: 25px;
    }

    .virtual_school_tour_wrapper .container-fluid {
        padding-left: 12px;
    }

    .process_year_content {
        padding-right: 0 !important;
    }

    .redgestration_form {
        margin-top: 140px;
    }

    .admsiion_section_active {
        width: 50%;
        height: 320px;
    }

    .admission_section_title {
        top: 16px;
        left: 46%;
        font-size: 18px;
    }

    .admission_section_content {
        padding: 17px;
        padding-top: 55px;
    }

    .admission_section_content p {
        font-size: 15px;
        line-height: 25px;
        width: 100%;
        text-overflow: ellipsis;
        -webkit-line-clamp: 8;
        max-height: calc(8* 24px);
        line-height: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .admission_read_more {
        margin-top: 0px;
    }

    .get_in_touch_content {
        display: none;
    }

    .prodecc_like_FAQ {
        display: block;
        margin-top: -65px;
    }

    .academisn_faq_sub_title {
        font-size: 15px;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
    }
    .rr_hovering_content p{
        line-clamp: 2;
        -webkit-line-clamp: 4;
        max-height: calc(4* 24px);
        line-height: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

}
