/* ScrollIndicator.css */
.scroll-indicator {
    background-color: #9a1d2e;
    top: 59px;
    height: 6px;
    left: 0;
    position: fixed;
    /* z-index: 9999; */
    z-index: +2;
    width: 0;
    transform: translateY(100%);
    transition: width 0.1s ease;
  }
  