.popup-title {
    font-size: 24px;
    font-weight: bold;
    color: #333; /* Dark text color */
    margin-bottom: 10px;
    text-align: center;
    position: relative;
  }
  
  .btn-control-feedback button{
margin-top: -14%;
margin-right: -11%;
  }
  .stars {
    color: transparent; /* Hide default star color */
    background: linear-gradient(45deg, #ffcc00, #ff9500); /* Gradient for stars */
    -webkit-background-clip: text; /* Clip background to text */
    background-clip: text;
    font-size: 28px; /* Slightly larger stars */
    animation: glow 1.5s infinite alternate; /* Glow animation */
  }
  
  @keyframes glow {
    0% {
      filter: brightness(100%);
    }
    100% {
      filter: brightness(120%);
    }
  }

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
  }
  
  .popup-content h2 {
    margin-bottom: 10px;
  }
  
  .popup-content p {
    font-size: 0.9rem;
    margin-bottom: 7px;
  }
  
  .star-rating {
    margin-bottom: 10px;
  }

  
  .star {
    /* padding: 1%; */
    font-size: 2.2rem;
    cursor: pointer;
    color: #ccc; /* Default star color */
    display: inline-block;
    transition: transform 0.2s ease, color 0.2s ease;
    border-radius: 50%; /* Makes the star rounded */

  }
  
  .star.filled {
    color: #ffcc00; /* Filled star color */
  }
  
  .star:hover {
    transform: scale(1.2); /* Slightly enlarge on hover */
  }
  
    
  .star svg {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Makes the star round */
    padding: 5px; /* Adds some spacing around the star */
  }
  
  textarea {
    width: 100%;
    height: 80px;
    padding: 10px;
    /* margin-bottom: 20px; */
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
  }
  
  button {
    border: none;
    background-color: #a23333;
    padding: 6px 25px;
    border-radius: 24px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #9a1d2eec;
  }