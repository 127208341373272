html {
    scroll-behavior: smooth;
}

.services_names {
    list-style: none;
    padding: 0px;
    margin: 0px;

}

.services_names li {
    display: flex;
    align-items: center;
    background-color: #f4f4f4 !important;
    padding: 10px 25px;
    margin: 14px;
    border-radius: 10px;
    height: 44px;
    justify-content: space-between;
    cursor: pointer;
}

.services_names li.active {
    background-color: #9a1d2e !important;
    /* Change background for active items */
    color: #fff;
    /* Ensure text is white */
    font-weight: 500 !important;
    /* Make text bold */
}

.services_names li:hover a,
.services_names .active a {
    color: #fff;
    font-weight: 500;
}

/* .services_names li:hover,
.services_names .active {
    background: linear-gradient(45deg, rgb(154 29 46), rgb(154 29 46));
    color: #fff;
} */
.services_names li:hover {
    background: linear-gradient(45deg, rgb(154 29 46), rgb(154 29 46));
    color: #fff;
}

.services_names li a {
    font-family: var(--common-font);
    font-size: 16px;
    color: #000;
    text-decoration: none;
}

.accordion-body {
    padding: 0px;
}

.accordion-item {
    background: rgb(255 255 255);
    margin-bottom: 15px;
    border-radius: 12px !important;
    overflow: hidden;

}

#groomingdownload_info .left_img {
    height: 375px;
}

.accordion-header {
    font-family: var(--common-font);
    font-size: 16px;
    color: #000;
    text-decoration: none;
    border-radius: 15px;
    background: rgba(237, 237, 237, 1);
}

.accordion-header button {
    border-radius: 15px !important;
    background: #fff !important;
}



.services_names li span img {
    width: 25px;
    height: 25px;
}


.accordian_body_main_div .accordion-item .accordion-header .accordion-button {
    box-shadow: none !important;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    line-height: 24px;
    font-size: 18px;
    background-color: #fff !important;
    color: #000;
    /* Default color */
    transition: color 0.3s ease;
    /* Smooth transition for color change */
}

.accordian_body_main_div .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    color: #9A1D2E;
    /* Active red color */
}

.services_names li a {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    line-height: 24px;
    font-size: 15px;
}

.accordian_body_main_div .accordion-item .accordion-header .accordion-button::after {
    display: none !important;
}

.side_bar_plus_minus svg {
    color: #000 !important;
}

.side_bar_item {
    border-top: 1px solid #dfdcdc !important;
}

.side_bar_item_fix.active {
    background-color: #9a1d2e !important;
    color: #fff;
    font-weight: 500 Im !important;
    /* Text color for contrast */
}

.registration_button button {
    padding-bottom: 3px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .services_names li {
        display: flex;
        padding: 10px 18px;
        margin: 14px;
        height: 44px;
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .services_names li {
        padding: 5px 7px;
        margin: 14px;
    }

    .register_side_bar a {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .register_side_bar {
        text-align: start;
    }

    .services_names li a {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        line-height: 24px;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: start;
    }
}

@media only screen and (max-width: 768px) {
    .services_names li a {
        font-size: 14px;
    }

    .services_names li span {
        font-size: 13px;
    }
    .register_accordian_main_body{
        display: none;
    }
}
