.App {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  /* overflow: hidden;  */
  overflow: hidden;
}
html {
  overflow-y: scroll; 
  overflow-x: hidden; 
  height: auto;
}
.App::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge, and others */
}
