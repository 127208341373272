.exam_gapping_div {
    padding-left: 42px;
    padding-right: 42px;
}

.inner_mainbanner {
    margin: 50px 0px 30px 0px;
    position: relative;
}

.uni_exam {
    margin-top: 35px;
}

.uni_exam h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
}

.uni_exam h2 span {
    font-family: graphik_medium;
    color: #a23333;
    font-weight: 600;
}

.uni_exam p {
    color: #575757;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    line-height: 24px;
    font-size: 16px;
    /* width: 67%; */
}

.side_bar_heading h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    line-height: 24px;
    font-size: 20px;
    color: #000;
}

.exam_placement_program {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 12px;
    padding: 20px 30px;
    overflow: auto;
    max-height: 820px;
}

.exam_placement_program::-webkit-scrollbar {
    display: none;
}

.exam_program_text h1 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 36px;
    line-height: 55px;
    color: #a23333;
}

.exam_program_text h1 span {
    color: #000 !important;
}

.exam_program_text p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    color: #8D8D8D !important;
}

.exam_webite_btn {
    background-color: #f7f3e1;
    border-radius: 10px;
    padding: 8px 3px;
    margin-top: 25px;
}

.exam_webite_list {
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 8px 3px;
}

.exam_btn button {
    padding: 7px 27px !important;
}

.exam_benifits_section {
    margin-top: 25px;
}

.exam_benifits_section h5 {
    font-family: graphik;
    color: #101010;
    font-size: 22px;
    line-height: 20px;
    font-weight: 500;
}

.exam_benifits_section ul li {
    color: #8D8D8D !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 3px !important;
}

.exam_read_now li {
    color: #8D8D8D !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 3px !important;
}

.exam_read_now li a {
    color: #a23333;
    font-weight: 500;
    font-style: italic;
    text-decoration: underline !important;
}

.exam_for_students h1 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    color: #a23333;
    margin-bottom: 0;
}

.exam_for_students h1 span {
    color: #000 !important;
}

.exam_stand_Exam ul li {
    color: #8D8D8D !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 3px !important;
}

.exam_webite_btn span {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.exam_benifits_section p {
    color: #8D8D8D !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 15px !important;
    width: 80%;
}

.exam_benifits_section ul li a {
    color: #a23333;
    font-weight: 500;
    font-style: italic;
    
    text-decoration: underline !important;
}

.exam_main_tabs {
    margin-top: 17px;
    display: flex;
    gap: 15px;
}

.exam_main_tabs button {
    border: 1px solid #d4cdcd;
    padding: 3px 25px;
    border-radius: 38px;
    background-color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    color: black;
}

.exam_main_tabs button.active {
    background-color: #a23333;
    ;
    color: #fff;
    border: 1px solid #a23333;
    ;
}

.exam_course {
    background-color: #d9d9d9;
    padding: 7px 16px;
    padding-right: 67px;
    border-radius: 7px;
    margin-bottom: 15px;
}

.exam_course span {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000;
}

.exam_history {
    margin-bottom: 16px;
    /* border: 1px solid #d9d9d9; */
    padding: 8px 13px;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    background-color: #f3f3f3 !important;
}

.exam_history:hover {
    background-color: #a23333 !important;
    color: white;
    border-color: none !important;
}

.exam_ap {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: #242424 !important;
}

.exam_history span {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: #3C3C3C;
}

.exam_peged {
    border: 1px solid #d9d9d9;
    padding: 2px 7px;
    width: 136px;
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
}

.exam_peged svg {
    cursor: pointer;
}

.exam_history:hover span {
    color: #fff;
}

.exam_history:hover .exam_ap {
    color: #fff !important;
}

.exam_test_center {
    background-color: #f8f4e2;
    border-radius: 8px;
    padding: 25px 53px !important;
}

.exam_test_center h5 {
    font-family: graphik;
    color: #101010;
    font-size: 22px;
    line-height: 20px;
    font-weight: 300;
}

.exam_test_center p {
    color: #363636 !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 3px !important;

}

.exam_test_center p span {
    font-weight: 600 !important;
}

.exam_testing_policies {
    margin-top: 25px;
}

.exam_testing_policies h5 {
    font-family: graphik;
    color: #101010;
    font-size: 22px;
    line-height: 20px;
    font-weight: 500;
}

.exam_testing_policies p {
    color: #363636;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;

}

.exam_testing_policies ul li {
    color: #8D8D8D !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 3px !important;
}

.exam_candidates {
    margin-top: 25px;
}

.exam_candidates h5 {
    color: #363636;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
}

.exam_candidates p {
    color: #8D8D8D !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 3px !important;
}

.exam_candidates ul {
    margin-top: 10px;
}

.exam_candidates ul li {
    color: #8D8D8D !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 3px !important;
}

.exam_regular {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 7px;
    transition: all 0.3s ease;
}

.exam_regular h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: #3C3C3C;
    margin-bottom: 0;
    transition: all 0.3s ease;
}

.exam_regular:hover {
    background-color: #a23333;
}

.exam_regular:hover h5 {
    color: #fff;
}

.exam_regular_main_div {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.exam_regular .flex-2 {
    width: 30%;
    text-align: start;
}

.exam_regular .flex-1 {
    width: 75%;
    text-align: start;
}

.exam_candidates ul li a {
    color: #a23333;
    font-weight: 500;
    font-style: italic;
    text-decoration: underline;
}

.exam_contact {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 7px;
    transition: all 0.3s ease;
}

.exam_contact h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: #5A5A5A;
    margin-bottom: 0;
    transition: all 0.3s ease;
}

.exam_contact .flex-2 {
    width: 50%;
    text-align: start;
}

.exam_contact .flex-1 {
    width: 50%;
    text-align: start;
}

.common_banner_img {
    padding-left: 42px;
    padding-right: 42px;
}

.common_banner_img img {
    width: 100% !important;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .exam_gapping_div {
        padding-left: 15px;
        padding-right: 15px;
    }

    .uni_exam h2 {
        font-size: 40px;
    }
    .exam_program_text h1 {
        font-size: 30px;
    }
    .inner_mainbanner {
        margin: 133px 0px 30px 0px;
        position: relative;
    }
}

@media only screen and (max-width: 768px) {
    .common_banner_img {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 150px;
    }

    .exam_gapping_div {
        padding-left: 3px;
        padding-right: 3px;
    }

    .uni_exam h2 {
        font-size: 25px;
        line-height: 35px;
    }

    .uni_exam {
        margin-top: 15px;
    }

    .uni_exam p {
        width: 100%;
        margin-bottom: 0;

    }

    .exam_program_text h1 {
        font-size: 18px;
        line-height: 30px;
    }

    .exam_placement_program {
        padding: 15px 16px;
        overflow: auto;
        max-height: 820px;
    }

    .exam_webite_btn {
        padding: 8px 3px;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        text-align: start;
        align-items: flex-start !important;
        gap: 13px;
    }

    .exam_webite_btn span {
        font-weight: 300;
    }

    .exam_webite_list {
        background-color: #f0f0f0;
        border-radius: 10px;
        padding: 8px 3px;
        display: flex;
        flex-direction: column;
        text-align: start;
        align-items: flex-start !important;
        gap: 13px;
    }

    .exam_benifits_section h5 {
        font-family: graphik;
        font-size: 16px;
        font-weight: 300;
    }

    .exam_for_students h1 {
        font-weight: 300;
        font-size: 16px;
        line-height: 28px;
    }

    .exam_course span {
        font-weight: 300;
        font-size: 16px;
    }

    .exam_history span {
        text-align: start;
    }

    .exam_test_center {
        border-radius: 8px;
        padding: 20px 15px !important;
    }

    .exam_test_center h5 {
        font-size: 19px;
        line-height: 27px;
        font-weight: 300;
    }

    .exam_main_tabs button {
        padding: 7px 14px;
        border-radius: 38px;
        font-size: 14px;
    }

    .exam_main_tabs {
        margin-top: 17px;
        gap: 10px;
    }

    .exam_benifits_section {
        margin-top: 6px;
    }

    .exam_regular {
        flex-direction: column;
        text-align: start;
        align-items: flex-start;
        gap: 19px;
        padding-top: 14px;
        padding-bottom: 14px;
    }

    .exam_regular .flex-1 {
        width: 100%;
        text-align: start;
    }

    .exam_regular .flex-2 {
        width: 100%;
        text-align: start;
    }

    .exam_benifits_section p {
        width: 100%;
    }
}

@media (min-width: 1024px) {
    .common_banner_img img {
        object-fit: cover;
        max-height: 500px; /* Ensures the height does not exceed 500px */
        width: 100%; /* Makes sure the image takes full width */
        object-position: center; /* Centers the image */
        display: block; /* Prevents unwanted spacing issues */
        margin-top: -30px;
    
    }
}
