@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: graphik;
    src: url("../../../public/font-family/GraphikMedium.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: graphik_light;
    src: url("../../../public/font-family/GraphikLight.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: graphik_medium;
    src: url("../../../public/font-family/GraphikMedium.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: graphik_bold;
    src: url("../../../public/font-family/GraphikBold.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}


.home_main_section h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 27px;
}

.section_gapping {
    padding-top: 60px;
    padding-bottom: 60px;
}

.c3_container {
    /* display: inline-flex; */
    margin-top: -70px;
    position: relative;
    align-items: center;
}

.curly_brace {
    font-size: 200px;
    color: #cccccc;
    font-weight: 300;
}

.c3_main {
    font-size: 170px;
    font-weight: 600;
    color: #a23333;
    margin-left: 5px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    line-height: 0px;
}

.c3_sup {
    font-size: 70px;
    color: #a23333;
    font-weight: 500;
    margin-right: 10px;
    font-family: "Poppins", sans-serif;
    /* position: absolute; */
    top: -70px;
    /* padding: 0 0 40px !important; */
}

.home_copetent_section {
    display: flex;
    text-align: center;
    align-items: center;
    gap: 10px;
}

.home_competency_text {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    line-height: 46px;
    font-size: 31px;
    text-align: start;
    margin-top: 11px;
    color: #a23333;
}

.home_main_section p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    line-height: 40px;
    font-size: 27px;
}

/* Rest of the CSS remains the same */

/* ///Butoons */
.buttons_main_section {
    width: 35%;
    height: 0;
}

.blob-btn {
    z-index: 1;
    position: relative;
    padding: 11px 28px;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #a23333;
    font-size: 16px;
    font-weight: 400;
    background-color: transparent;
    outline: none;
    border: none;
    transition: color 0.5s;
    cursor: pointer;
    border-radius: 30px;
    font-family: "Poppins", sans-serif;
}

.blob-btn:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #a23333;
    border-radius: 30px;
}

.blob-btn:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 100%;
    height: 100%;
    transition: all 0.3s 0.2s;
    border-radius: 30px;
}

.blob-btn:hover {
    color: #FFFFFF;
}

.blob-btn:hover:after {
    transition: all 0.3s;
    left: 0;
    top: 0;
}

.blob-btn__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: #ffffff;
}

.blob-btn__blobs {
    position: relative;
    display: block;
    height: 100%;
    filter: url('#goo');
}

.blob-btn__blob {
    position: absolute;
    top: 2px;
    width: 25%;
    height: 100%;
    background: #a23333;
    border-radius: 100%;
    transform: translate3d(0, 150%, 0) scale(1.7);
    transition: transform 0.45s;
}

.blob-btn__blob:nth-child(1) {
    left: 0;
    transition-delay: 0s;
}

.blob-btn__blob:nth-child(2) {
    left: 30%;
    transition-delay: 0.08s;
}

.blob-btn__blob:nth-child(3) {
    left: 60%;
    transition-delay: 0.16s;
}

.blob-btn__blob:nth-child(4) {
    left: 90%;
    transition-delay: 0.24s;
}

.blob-btn:hover .blob-btn__blob {
    transform: translateZ(0) scale(1.7);
}

/* ///Butoons css end here*/






.event_card {
    background: #565656;
    border-radius: 23px;
}

.home_event_card:hover {
    background-color: #9a1d2e;

}

.home_event_custom_card {
    border-radius: 22px;
    overflow: hidden;
}

.home_event_slider_bottom {
    color: #fff;
    padding: 10px 20px;
    text-align: center !important;

}




.home_all_button {
    display: inline-block;
    /* padding: 0.5rem 1rem; */
    border-radius: 10rem;
    color: #9a1d2e !important;
    font-size: 16px;
    /* letter-spacing: 0.1rem; */
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    width: max-content;
    padding: 10px 27px;
}

.home_all_button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10rem;
    z-index: -2;
    border: 1px solid #9a1d2e;
}

.home_all_button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #9a1d2e;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
}

.home_all_button:hover {
    color: #fff !important;
}

.home_all_button:hover:before {
    width: 100%;
}



.home_main_section {
    margin-left: 90px;
    /* margin-top: 5rem; */
}

.home_slider_main_section {
    overflow: hidden;
    /* margin-top: 2rem; */
    padding-right: 0;
    padding-left: 0;
}

.home_section_slider {
    padding-right: 0;
}

.home_slider_main_content {
    margin-left: 0;
}

.order_delivery_img {
    border: 12px solid #ebebeb;
    border-top: 0;
    border-right: 0;
    border-bottom-left-radius: 255px;
    padding: 10px;
    padding-top: 0;
    padding-right: 0;

    overflow: hidden;
    height: 80vh;
    box-sizing: border-box;
}

.order_delivery_img img,
video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* border-radius: inherit; */
    height: 77vh !important;
    border-radius: 0px 0px 0px 235px;
}

.order_delivery_main_img {
    border-radius: 0px 0px 0px 235px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* border-radius: inherit; */
    height: 77vh !important;
}

/* Custom styles for the slick slider dots */
.home_slider_main_section .slick-dots {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    left: 27%;
    list-style: none;
    z-index: 2;
    top: 86%;
}

.home_slider_main_section .slick-dots li {
    width: 33px;
    height: 5px;
    margin: 0 5px;
}

.home_slider_main_section .slick-dots li button {
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: #fff !important;
    border: none;
    outline: none;
    opacity: 0.5;
    transition: opacity 0.3s ease;
}

.home_slider_main_section .slick-dots li.slick-active button {
    background-color: #a23333 !important;
    opacity: 1;
}

.home_slider_main_section .slick-dots li button:before {
    display: none;
}

/* /// A school css write here*/
.home_school_heading {
    margin-bottom: 2rem;
}

.all_fluid_main_class_next {
    padding-left: 90px;
    padding-right: 90px;
}

.home_school_heading h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
}

.home_school_heading .highlight {
    color: #a23333;
}

.home_school_heading p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    color: #8D8D8D;
}

.home_school_boxes {
    display: flex;
    list-style: none;
    width: fit-content;
    gap: 50px !important;
    justify-content: center;
    margin: auto;
}

.home_school_info_box {
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    text-align: left;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    width: 328px;
}

.home_school_info_box h4 {
    font-weight: 400;
    font-size: 25px;
    line-height: 37px;
    font-family: graphik_light;
}

.home_school_info_box .number {
    font-size: 65px;
    font-weight: bold;
    font-family: graphik;
    color: #f0d8d8;
    line-height: 70px;
}

.home_school_info_box:hover {
    background-color: #F4F0DD;
}

.home_school_info_box:hover .number {
    color: #a23333;
}


.home_school_info_box p {
    color: #737373;
    margin-bottom: 0;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
}

/* /// A school css write end here*/

/* // message founder start css here */
.home_founder_section {
    background-color: #9A1D2E;
    padding: 20px 0;
}

.home_founder_message_row {
    padding: 20px;
}

.founder-image img {
    width: auto;
    height: 100%;
}

.founder_message_all_text h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 51px;
}

.founder_message_all_text p {
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;
    width: 93%;
}

.all_button {
    display: inline-block;
    padding: 10px 27px;
    border-radius: 10rem;
    color: #fff;
    font-size: 16px;
    /* letter-spacing: 0.1rem; */
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    width: max-content;
}

.all_button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #9a1d2e;
    border-radius: 10rem;
    z-index: -2;
    border: 1px solid #fff;
}

.all_button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #fff;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
}

.all_button:hover {
    color: #000;
}

.all_button:hover:before {
    width: 100%;
}

.founder_school_btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
}

/* // message founder end css here */

.campus_middle_card_section img {
    width: 100%;
}

.campus-card {
    overflow: hidden;
    border-radius: 15px;
}

.campus_middle_card_div {
    z-index: 99;
    top: 87%;
    width: 100%;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.campus-card:hover .campus_middle_card_div {
    top: 0%;
    background: var(--hover-gradient, linear-gradient(to bottom, #01648ed8, #01648e));
    animation: slideUp 0.8s ease-in-out forwards;
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.campus_middle_card_div ul {
    list-style: none;
}

.our_campus_map_section div {
    height: 100%;
}

.our_campus_map_section div iframe {
    height: 100%;
    border-radius: 15px;
}

.campus_middle_third_card img {
    width: 100%;
    border-radius: 15px;
    position: relative;
}

.campus-card::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: var(--dynamic-gradient, linear-gradient(to bottom, transparent, #01648e));
    z-index: 1;
}

.yellow::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, transparent, #CA7600);
    z-index: 1;
}

.yellow:hover .campus_middle_card_div {
    background: linear-gradient(to bottom, #ff9500c7, #CA7600);
}

/* .yellow .campus_middle_card_div {
    top: 83%;
} */

.campus_middle_third_card {
    bottom: 0px;
    background: linear-gradient(180deg, rgba(255, 149, 0, 0) 53.84%, #CA7600 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    border-radius: 15px;
}

.campus_middle_third_card p {
    bottom: 0px;
    width: 100%;
    position: absolute;
    font-family: graphik;
    font-size: 22px;
}


.visit_campus {
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 10rem;
    color: #fff;
    font-size: 16px;
    /* letter-spacing: 0.1rem;  */
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    width: 100%;
    background: none;
}

.visit_campus:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    border-radius: 10rem;
    z-index: -2;
    border: 1px solid #fff;
}

.visit_campus:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: #fff;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
}

.visit_campus:hover {
    color: #000;
}

.visit_campus:hover:before {
    width: 100%;
}

.campus_middle_card_div ul {
    padding-left: 0;
    margin-bottom: 55px
}

.kc_heigh_heading {
    font-size: 18px !important;
    color: #fff !important;
    margin-bottom: 122px;
    font-family: graphik;
}

.campus-card:hover .kc_heigh_heading {
    margin-bottom: 10px;
}

.campus_location {
    color: #fff !important;
}

.campus_location .location_group {
    width: 55px !important;
}

.campus_location svg {
    color: #fff !important;
    background-color: #0808083d !important;
}


.campus_location span {
    margin-left: 11px;
    margin-left: 9px;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

/* //////// kc album css here */
.kc_album_section {
    width: 100%;
    bottom: -20px;
    left: 50%;

}

.kc_music_group {
    background-color: #1c1c1c;
    border-radius: 100px;
    width: 100%;
    /* box-shadow: rgb(250 250 250 / 24%) 0px 3px 8px; */
    box-shadow: rgb(255 238 238 / 35%) 0px 5px 15px;
}

.kc_music_input {
    background-color: #4a4a4a;
    border-radius: 30px;
    border: 1px solid #fff;
    padding: 2px;
    width: 100%;
}

.kc_music_input button {
    width: 100% !important;
    padding: 0 !important;
}

.kc_music_input input:focus {
    outline: none;
    box-shadow: none;
}

.kc_music_group_div {
    padding: 0px 10px;
}

.kc_album_section h1 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 51px;

}

.kc_music_svg_section svg {
    color: gray;
    font-size: 16px;
    cursor: pointer;
}

.kc_music_input span {
    /* background-color: #000 !important; */
    border-radius: 100px;
    padding: 7px 10px;
    border-top-right-radius: 100px !important;
    border-bottom-right-radius: 100px !important;
    margin: 1px;
}

.kc_music_input svg {
    font-size: 14px;
    margin-right: 1px;

}

.fa_music {
    background-color: black;
    border-radius: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    width: 37px;
    height: 37px;
    justify-content: center;
    margin-left: 2px;
}



.kc_music_svg_input input {
    width: 110px;
}

/* .kc_music_svg_input input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 60%;
    height: 8px;
    background: #fff;
    border-radius: 5px;
    outline: none;
}

.kc_music_svg_input input[type="range"]::-webkit-slider-runnable-track {
    background: #fff;
    border-radius: 5px;
    height: 8px;
}

.kc_music_svg_input input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.kc_music_svg_input input[type="range"]::-moz-range-track {
    background: #2b2b2b;
    border-radius: 5px;
    height: 8px;
}

.kc_music_svg_input input[type="range"]::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.kc_music_svg_input input[type="range"]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 8px 0;
    color: transparent;
}

.kc_music_svg_input input[type="range"]::-ms-fill-lower {
    background: #fff;
    border-radius: 5px;
}

.kc_music_svg_input input[type="range"]::-ms-fill-upper {
    background: #fff;
    border-radius: 5px;
}

.kc_music_svg_input input[type="range"]::-ms-thumb {
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}
 */











.range {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;
}

.range-input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    height: 6px;
    background: linear-gradient(to right, #fff var(--slider-fill-percentage), #585252 calc(var(--slider-fill-percentage) + 1%));
}

.range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: none;
    transition: 0.2s ease-in-out;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}

.range-input::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: none;
    transition: 0.2s ease-in-out;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}

.range-input::-webkit-slider-thumb:hover {
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}

.range-input:active::-webkit-slider-thumb {
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}

.range-input:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}

.range-input::-moz-range-thumb:hover {
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}

.range-input:active::-moz-range-thumb {
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}

.range-input:focus::-moz-range-thumb {
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}









.value-display {
    font-size: 26px;
    width: 50px;
    text-align: center;
    color: #fff;
}

.accreditations_image {
    margin-top: 2rem;
}

/* .accreditations_image div {
    filter: grayscale(1);
} */
.accreditations_image div {}

.accreditations_image img {
    max-height: 80px;
    object-fit: contain;
    filter: grayscale(1);
    transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
    cursor: pointer;
}

.accreditations_image img:hover {
    filter: grayscale(0);
    transform: scale(1.2);
}

.kc_album_img {
    filter: grayscale(1);
    height: 65vh;
    object-fit: cover;
}

.accreditations_partnerships {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 51px;
    color: #a23333;
}

.accreditations_partnerships_main_section {
    overflow: hidden;
}

.accreditations_partnerships span {
    color: #000 !important;
}

/* ////// Qucick connet */
/* / // Quick connect start css here / */
.quick_connect_container {
    /* background: linear-gradient(89.83deg, rgb(13 13 13 / 82%) 15.86%, #9A1D2E 99.94%); */
    padding: 0;
    position: relative;
    height: 685px;
    background-color: #f4f0dd;

}

.quick_connect_container h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 51px;
    color: #fff;
}

.quick_connect_container p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #fff;
    line-height: 24px;
}

.quick_connect_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.quick_connect_item {
    text-align: center;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.quick_connect_item h5 {
    font-size: 18px;
    font-family: graphik_medium;
    margin-bottom: 0;
    margin-top: 10px;
}

.quick_connect_item img {
    margin-bottom: 10px;
    max-height: 55px;
    transition: transform 0.3s ease;
}

.quick_connect_item:hover img {
    transform: scale(1.2);
}

.quick_connect_section {
    background-color: #fff;
    border-radius: 15px;
    position: relative;
    top: 201px;
    width: 94%;
    padding: 25px 0;
    padding-bottom: 25px;
}

.quick_connect_section .row .quick_connect_item:not(:nth-child(4n)) {
    border-right: 1px solid #ddd;
}

.quick_connect_section .row .quick_connect_item:last-child {
    border-right: none;
}

/* /////////////
 */
.event_slider_main_section {
    background-color: #f4f0dd;
}

.event_slider_main_section .row {
    padding-top: 0px;
    padding-bottom: 70px;
}

.event_slider_main_section .slide-item {
    padding-right: 30px;
}

/* .slick-track{
    transform: translate3d(-820.5px, 0px, 0px) !important; 
} */




.news-title {
    font-size: 24px;
    color: #801515;
    font-weight: 700;
}


.home_white_line {
    width: 160px;
    height: 8px;
    background-color: #ffffff;
}


.event_slider_news_events {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
    color: #a23333;
    margin-bottom: 0 !important;
}

.event_slider_news_events span {
    color: #000 !important;
}

.event_news_subtitle {
    font-family: graphik;
    font-size: 34px;
    color: #000;
    margin-bottom: 25px !important;
    font-weight: 400;
}

.event_news_subtitle span {
    color: #a23333;
    font-weight: 500;
}

.home_event_news_content {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    /* color: #8D8D8D; */
    margin-top: 25px;
    color: rgba(141, 141, 141, 1);
}

.event_slider_left_btn {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.event_slider_left_btn button {
    display: inline-block;
    padding: 10px 27px;
    border-radius: 10rem;
    color: #9a1d2e !important;
    font-size: 16px;
    /* letter-spacing: 0.1rem; */
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    width: max-content;
    font-weight: 500;
}

.event_slider_left_btn button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10rem;
    z-index: -2;
    border: 1px solid #9a1d2e;
}

.event_slider_left_btn button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #9a1d2e;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
}

.event_slider_left_btn button:hover {
    color: #fff !important;
}

.event_slider_left_btn button:hover:before {
    width: 100%;
}





/* ????????? */
.event_slider_contnet h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #9A1D2E;
}

.event_slider_contnet h4 span {
    color: #727272;
    font-size: 16px !important;
}

.event_slider_contnet h5 {
    font-family: graphik;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    text-overflow: ellipsis;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    max-height: calc(2* 24px);
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.event_slider_contnet p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #8D8D8D;
    overflow: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 3;
    -webkit-line-clamp: 2;
    max-height: calc(50px);
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.home_event_custom_card:hover {
    border-bottom: 8px solid #b20000;
}

.home_card_img_container {
    padding: 16px;
}

.home_card_img_container img {
    border-radius: 8px;
    /* Rounded corners for image */
}

.home_event_card_body {
    padding: 10px 16px;
}

.home_event_card_bottom_date {
    background-color: #565656;
    color: white;
    text-align: center;
    padding: 8px;
    border-radius: 0 0 16px 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.home_event_custom_card:hover .home_event_card_bottom_date {
    background-color: #b20000;
}


/* ////////////// */

.home_diffrent_div {
    border-radius: 15px;
    /* overflow: hidden; */
    background-color: #f0f0f0;
    height: 500px !important;
}

.home_diffrent_div:hover {
    background-color: #9A1D2E;
    color: #fff !important;
}

.home_diffrent_text_section {
    padding: 0px 20px 15px 20px;
}

.home_diffrent_text_section h5 {
    font-family: graphik;
    font-size: 26px;
    color: rgba(0, 0, 0, 1) !important;

}

.home_diffrent_text_section p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: rgba(87, 87, 87, 1) !important;
    overflow: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 3;
    -webkit-line-clamp: 7;
    max-height: calc(148px);
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;

}

.home_diffrent_div:hover .home_diffrent_text_section p {
    color: #fff !important;
}

.home_diffrent_div:hover .home_diffrent_text_section h5 {
    color: #fff !important;
}

.home_sernior {
    border: 2px solid #fff;
    margin: 20px 20px 0px 20px;
    height: 459px;
    position: absolute;
    display: flex;
    top: 0px;
    border-radius: 15px;
    /* margin-right: 0px !important; */
    width: -webkit-fill-available;
    align-items: flex-end;
}

.home_diffrent_img_section {
    filter: grayscale(1);
}

.home_diffrent_div:hover .home_diffrent_img_section {
    filter: grayscale(0);
}

.home_diffrent_img_section::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    /* Adjust the height of the gradient */
    background: linear-gradient(to bottom, transparent, #f0f0f0);
    /* Gradient effect */
    z-index: 1;
}

.home_diffrent_div:hover .home_diffrent_img_section::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    /* Adjust the height of the gradient */
    background: linear-gradient(to bottom, transparent, #9A1D2E);
    /* Gradient effect */
    z-index: 1;
}

/* .home_diffrent_div button {
    display: none;
    
}

.home_diffrent_div:hover button {
    display: block;
    z-index: 9999999999;
    position: absolute;
    border-radius: 100px;
    padding: 7px 20px;
    font-size: 16px;
    font-weight: 400;
    border: none;
    background: #fff;
    box-shadow: 0 9px 10px #ececec;
    left: 40px;
    bottom: -10px;
} */

/* // button open smootly */
.home_diffrent_div button {
    opacity: 0;
    transform: translateY(20px);
    z-index: -1;
    position: absolute;
    border-radius: 100px;
    padding: 7px 20px;
    font-size: 16px;
    font-weight: 400;
    border: none;
    background: #fff;
    box-shadow: 0 9px 10px #ececec;
    left: 40px;
    bottom: -10px;
    transition: opacity 0.4s ease, transform 0.4s ease;
    color: #9a1d2e;
}

.home_diffrent_div:hover button {
    opacity: 1;
    background-color: none;
    transform: translateY(0);
    z-index: 9999;

/* color : white !important */
}
.home_live_learn:hover{
    background: #9a1d2e;
    color:#fff ;
}

.dropdown_main_section {
    transform: translate3d(0px, 2px, 0px) !important;
    width: 96%;
    background-color: #606060;
    padding-inline: 17px;
    color: #fff !important;
    margin-left: 18px;
    border-bottom-left-radius: 11px !important;
    border-radius: 0;
    border-bottom-right-radius: 11px !important;
}

.dropdown_main_section li a {
    background-color: #606060 !important;
}

.dropdown_main_section li a:hover {
    color: #fff !important;
}

.all_campus_link {
    text-align: start;
}

.horizontal_line {
    border: 1px solid #cecece;
    border-radius: 10px;
    width: 95%;
    margin: auto;
    display: none;
}

.home_right_slider_section {
    display: none;
}

.visit_btn {
    margin-top: -20px !important;
}

.accreditations_image .col-6 {
    padding-left: 10px;
    padding-right: 10px;
}

.accreditations_image .img-fluid {
    margin: 0 auto;
}

.home_diffrent_img_section img {
    border-radius: 15px;
}

.music_icon_data {
    width: 165px;
    display: flex;
    justify-content: space-between;
}

.music_icon_data img {
    cursor: pointer;
    width: 16px;
}

.slick-list {
    padding: 0 50px 0 0 !important;
}

.right_border {
    border-right: 1px solid #ddd;
}

/* /// side red sticky button */
.side_tab_container {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    width: 41px;
}

/* Style for the side tab */
.side_tab_container .side-tab {
    align-items: center;
    background-color: #9a1d2e;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
    box-shadow: 0 4px 8px #0000001a;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    line-height: 30px;
    padding: 27px 6px;
    text-align: center;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
}

.side_tab_container .side-tab:hover {
    background-color: rgba(154, 29, 46, 1);
}

/* /// side red end sticky button */

.quick_cc {
    background: linear-gradient(89.83deg, rgb(13 13 13 / 82%) 15.86%, #9A1D2E 99.94%);
    padding: 65px;
    position: absolute;
    width: 100%;
    left: 0;
    height: 54vh;
}

.event_slide {
    text-align: center;
    align-items: center;
}

.music_drop_down .music {
    color: #fff;
}

.event_slider_img_right {
    width: 100%;
    height: 305px;
    object-fit: cover;
}

/* .music_drop_down .music:hover {
    color: #9a1d2e !important;
} */

.campus_own_gapping {
    padding-top: 60px;
    padding-left: 90px;
    padding-right: 90px;
}

.event_view_news {
    padding: 10px 32px !important;
}

.a_school {
    padding-bottom: 35px !important;
}

/* .blue .campus_middle_card_div ul {
    margin-bottom: 58px !important;
} */
/* body.our_campus_blue .blue .campus_middle_card_div ul {
    margin-bottom: 24px !important;
} */


.event_slider_for_row .slick-dots li.slick-active button::before {
    color: #a23333 !important;
}

.event_slider_for_row .slick-slider ul {
    bottom: -38px !important;
    text-align: left !important;
}

.event_slider_for_row .slick-dots li button::before {
    font-size: 11px;
    color: rgb(164, 164, 164) !important;
    opacity: 1;
}
.event_slider_for_height{
    height: 477px;
}
.anniversary_btn_link a{
    text-decoration: none !important;
}
/* //////////////// */
@media (min-width: 1600px) and (max-width: 2500px) {

    .order_delivery_img {
        border: 12px solid #ebebeb;
        border-top: 0;
        border-right: 0;
        border-bottom-left-radius: 255px;
        padding: 10px;
        padding-top: 0;
        padding-right: 0;

        overflow: hidden;
        height: 80vh;
        box-sizing: border-box;
    }

    .order_delivery_img img,
    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        /* border-radius: inherit; */
        height: 78vh !important;
        border-radius: 0px 0px 0px 235px;
    }

    .home_all_button {
        width: auto;
        padding: 8px 25px;
    }

    .all_button {
        width: auto;
        padding: 8px 25px;
    }

    .home_diffrent_div {
        height: 640px !important;
    }

    .home_sernior {
        height: 600px;
    }

    .home_diffrent_text_section {
        padding: 0px 20px 30px 20px;
    }

    .event_slider_contnet h5 {
        font-size: 20px;
    }

    .yellow .campus_middle_card_div {
        top: 84%;
    }

    .campus_middle_card_div {
        top: 79%;
    }

    .order_delivery_img img,
    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        /* border-radius: inherit; */
        height: 77vh !important;
        border-radius: 0px 0px 0px 235px;
    }
}

@media (min-width: 1400px) and (max-width: 1500px) {
    /* .event_slider_main_section .row {
        padding-top: 310px;
        padding-bottom: 70px;
    } */

}

@media (min-width: 1200px) and (max-width: 1400px) {
    .home_school_info_box {
        padding: 30px;
        border-radius: 10px;
        cursor: pointer;
        text-align: left;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        width: 310px;
    }


    .home_diffrent_text_section p {
        font-size: 14px;
        line-height: 23px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
    }

    /* .event_slider_main_section .row {
        padding-top: 310px;
        padding-bottom: 70px;
    } */
    .event_slider_main_section .row {
        padding-top: 0px;
        padding-bottom: 70px;
    }

    .event_slider_news_events {
        font-size: 42px;
    }

    .event_news_subtitle {
        font-size: 28px;
    }

    .campus_middle_card_div {
        top: 82%;
    }

    .campus_location .location_group {
        width: 89px !important;
    }



    .event_slider_contnet h5 {
        font-size: 18px;
        line-height: 25px;
    }

    .home_sernior {
        height: 440px;
    }

    .home_diffrent_div {
        height: 474px !important;
    }

    .home_diffrent_text_section {
        padding: 0px 8px 15px 8px;
    }



    .event_slider_main_section .slide-item {
        padding-right: 30px;
    }

    .kc_heigh_heading {
        font-size: 22px !important;
        color: #fff !important;
        margin-bottom: 40px;
        font-family: graphik;
    }

    .event_slider_img_right {
        height: auto;
    }

    .campus_middle_card_div ul {
        padding-left: 0;
        margin-bottom: 24px;
        /* Default for most browsers */
    }

    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        .campus_middle_card_div ul {
            margin-bottom: 24px !important;

        }

        .kc_heigh_heading {
            font-size: 19px !important;
        }
    }

}

/* //////////// Tabs view for media query */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .home_main_section {
        margin-left: 7px;
        margin-top: 50px;
    }

    .home_slider_main_section {
        margin-top: 6rem;
    }

    .curly_brace {
        font-size: 165px;
    }

    .home_competency_text {
        line-height: 39px;
        font-size: 25px;
        margin-top: 11px;
    }

    .c3_main {
        font-size: 125px;
    }

    .all_fluid_main_class_next {
        padding-left: 45px;
        padding-right: 00px;
    }

    .c3_sup {
        font-size: 53px;
    }

    .home_school_heading br {
        display: none;
    }

    .home_school_info_box {
        padding: 15px;
        width: 252px;
    }

    .home_school_boxes {
        padding-left: 0;
        gap: 25px !important;

    }

    .home_school_info_box p {
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .founder_message_all_text p {
        width: 86%;
    }

    .all_fluid_main_class {
        padding-left: 14px;
        padding-right: 14px;
    }

    .home_diffrent_text_section {
        padding: 0px 7px 15px 7px;
    }

    /* .home_diffrent_div {
        height: 444px !important;
    }

    .home_sernior {
        height: 408px;
    } */

    .home_school_heading h2 {
        font-size: 40px;
    }

    .founder_message_all_text h2 {
        font-size: 40px !important;
    }

    .quick_connect_container h2 {
        font-size: 40px;
        line-height: 29px;
    }

    .event_slider_news_events {
        font-size: 40px;
        line-height: 55px;
        display: flex;
        gap: 5px;
    }

    .accreditations_partnerships {
        font-size: 40px;
    }

    .kc_album_section h1 {
        font-size: 40px;
    }

    .campus_own_gapping {
        padding-top: 60px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .campus_middle_card_div {
        top: 78%;
    }

    .kc_heigh_heading {
        font-size: 20px !important;
    }

    .yellow .campus_middle_card_div {
        top: 78%;
    }

    .a_school {
        padding-left: 0px !important;
        padding-right: 00px;
    }

    .home_school_info_box h4 {
        font-size: 20px;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .home_school_info_box .number {
        font-size: 55px;
    }

    .home_main_section p {
        line-height: 36px;
        font-size: 23px;
    }

    .order_delivery_img {
        height: 69vh;
    }

    .order_delivery_img img,
    video {
        height: 66vh !important;
    }

    .order_delivery_main_img {
        border-radius: 0px 0px 0px 235px;
        height: 66vh !important;
    }

    .home_diffrent_div {
        /* overflow: hidden; */
        height: 645px !important;
    }

    .home_sernior {
        height: 610px;
    }

    .our_campus_map_section {
        display: none;
    }

    .campus_first {
        width: 50% !important;
    }
    .related_image {
        max-height: 10vh;
    }
}

@media only screen and (max-width: 768px) {
    .home_school_heading h2 {
        font-size: 25px;
        line-height: 30px;
        /* width: 72%; */
    }

    .home_united_br {
        display: none;
    }

    .home_school_heading p {
        font-size: 16px;
        line-height: 24px;
    }

    .home_school_heading p br {
        display: none;
    }

    .all_fluid_main_class {
        padding-left: 10px;
        padding-right: 10px;
    }

    .section_gapping {
        padding-top: 20px;
        padding-bottom: 0px;
    }

    .our_campus_row_section {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-wrap: unset;
        gap: 39px;
    }

    .our_campus_main_content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .campus_first {
        width: 100%;
    }

    .kc_heigh_heading {
        font-size: 22px !important;
        margin-bottom: 128px;
    }

    .campus_location span {
        font-size: 16px;
    }

    .kc_album_img {
        height: 40vh;
    }

    .kc_album_section h1 {
        font-size: 25px;
    }

    .kc_music_group {
        border-radius: 15px;
    }

    .accreditations_partnerships {
        font-size: 25px;
    }

    .event_slider_main_section .slide-item {
        padding-right: 0px;
    }

    .quick_connect_container {
        height: 720px;
    }

    .event_slider_left_btn {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    .event_slider_left_btn button {
        font-size: 13px;
        /* width: 39%; */
    }

    .event_slider_main_section .row {
        padding-top: 0px;
        padding-bottom: 37px;
    }

    .event_slider_contnet h5 {
        font-size: 18px;
        line-height: 25px;
    }

    .home_event_slider_bottom {
        /* padding: 14px 20px; */
        line-height: 15px;
    }

    .event_slider_contnet p {
        font-size: 14px;
    }

    .event_slider_contnet h4 {
        font-size: 16px;
    }

    .event_slider_contnet h4 span {
        font-size: 14px !important;
    }

    .quick_connect_item img {
        margin-bottom: 10px;
        max-height: 45px;
    }

    .quick_connect_item img {
        margin-bottom: 10px;
        max-height: 45px;
    }

    .quick_connect_section {
        top: 210px;
        width: 100%;
        padding: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .quick_connect_container h2 {
        font-size: 25px;
        margin-top: 24px;

    }

    .quick_connect_container p {
        font-size: 16px;
    }

    .event_slider_news_events {
        font-size: 25px;
        /* line-height: 35px; */
        margin-bottom: 0 !important;
    }

    .event_slider_mobile_view {
        padding: 0 !important;
        /* padding-top: 1rem !important; */
        padding-bottom: 2rem !important;
    }

    .event_slider_for_row {
        padding-left: 0;
        padding-right: 0;
    }

    .quick_connect_item h5 {
        font-size: 13px;
    }

    .event_news_subtitle {
        font-size: 20px;
        margin-bottom: 17px !important;
    }

    .home_diffrent_text_section h5 {
        font-size: 22px;
        line-height: 45px;
    }

    .home_diffrent_text_section p {
        font-size: 14px;
        line-height: 23px;
    }

    .diffrent_way_mobile {
        display: none;
    }

    .home_founder_message_row {
        flex-wrap: nowrap;
        flex-direction: row;
    }

    .home_founder_sub_image {
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 0px;
        padding-right: 0;
        padding-left: 25px !important;
    }

    .home_founder_message_row {
        padding: 0px;
        /* width: 100%; */
        margin-bottom: 25px;
    }

    .founder_message_all_text {
        margin-left: 0 !important;
        margin-top: 10px;
    }

    .founder_message_all_text h2 {
        font-size: 25px !important;
        line-height: 32px;
        font-weight: 300;
        width: 80%;
        font-family: graphik_bold;
    }

    .founder_image_mobile h2 {
        font-family: graphik_bold;
        font-weight: 700;
        font-size: 25px;
        line-height: 31px;
        text-align: start;
    }

    .founder_school_btn {
        margin-top: 15px;
        flex-direction: column-reverse;
        /* rotate: 90deg; */
        display: flex;
        align-items: unset;
        /* justify-content: space-evenly; */
        align-content: flex-end;
        gap: 130px;
    }

    .all_button {
        padding: 0.5rem 1rem;
        font-size: 13px;
        width: max-content;
        rotate: 90deg;
        letter-spacing: 0;
    }

    .founder_image_mobile {
        display: flex;
        text-align: center;
        align-items: center;
        width: 100%;
        gap: 15px;
    }

    .founder_image_mobile img {
        width: 54px;
    }

    .quick_connect_section .row .quick_connect_item:not(:nth-child(3n)) {
        border-right: 1px solid #ddd;
    }

    /* .quick_connect_section .row .quick_connect_item:not(:nth-child(3n)):not(:last-child) {
        border-right:none ;
    } */

    .section_divider {
        opacity: 0.10;
        margin: 0;
    }

    .horizontal_line {
        border: 1px solid #cecece;
        border-radius: 10px;
        width: 95%;
        margin: auto;
        display: block;
    }

    .all_fluid_main_class_next {
        padding-left: 0px;
        padding-right: 0px;
    }

    .home_school_boxes {
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 20px;
        padding-inline-start: 0;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;

    }

    .home_school_info_box {
        width: 100%;
        background-color: #F4F0DD;
        padding: 0px 0px;
    }

    .home_school_heading {
        margin-bottom: 1rem;
        /* text-align: start !important; */
        /* padding: 15px; */
    }

    .home_school_no_div {
        display: flex;
        /* text-align: right; */
        align-items: center;
        gap: 10px;
        justify-content: flex-start;
        width: 100%;
    }

    .home_number_main_section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 4px 4px 0px #0000001A;
        border-radius: 10px;
        padding: 0 12px;
    }

    /* .home_number_main_section.active {
        box-shadow: 0px 4px 4px 0px #0000001A !important;
    } */

    .home_school_info_box .number {
        font-size: 22px;
        color: #a23333 !important;
        line-height: 52px;
    }

    .home_school_info_box h4 {
        font-weight: 400;
        font-size: 16px;
        color: #000;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .home_school_boxes {
        gap: 24px !important;

    }

    .home_school_info_box p {
        font-size: 16px;
        line-height: 27px;
        padding-bottom: 15px;
        padding: 10px 12px;
    }

    .home_number_main_section svg {
        font-size: 18px;
        font-weight: 600;
    }

    .ReactCollapse--collapse {
        transition: height 0.4s ease;
    }

    .ReactCollapse--content {
        /* padding: 10px; */
        transition: opacity 0.4s ease;
        opacity: 1;
    }

    .ReactCollapse--content:exit {
        opacity: 0;
    }

    /* /////////// Top section header */
    .home_section_slider {
        padding-right: 0;
        display: none;
    }

    .home_right_slider_section {
        display: block;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .order_delivery_img {
        border: none;
        border-top: 0;
        border-right: 0;
        border-bottom-left-radius: unset;
        padding: 0px;
        padding-top: 0;
        padding-right: 0;
        overflow: hidden;
        height: 80vh;
    }

    .home_slider_main_content {
        margin-left: 0;
        flex-direction: column-reverse;
    }

    .home_left_kc_section {
        width: 100%;
    }

    .order_delivery_main_video {
        height: 75vh;
    }

    .home_main_section h4 {
        font-size: 18px;
        align-items: center;
        text-align: center;
    }

    .home_main_section {
        margin-left: 0px;
        /* margin-top: 5rem; */
    }

    .c3_main {
        font-size: 100px;
        color: #fff;
    }

    .curly_brace {
        font-size: 117px;
        color: #cccccc;
        font-weight: 300;
    }

    .c3_sup {
        font-size: 44px;
        top: -45px;
        color: #fff;
    }

    .c3_container {
        margin-top: -33px;
    }

    .home_competency_text {
        font-weight: 800;
        line-height: 32px;
        font-size: 21px;
        text-align: start;
        color: #fff;
    }

    .home_slider_main_section .slick-dots {
        display: none !important;
    }

    .home_all_button {
        display: inline-block;
        padding: 0.5rem 1rem;
        width: 50%;
        font-size: 14px;
    }

    .home_slider_br {
        display: none;
    }

    .home_main_section p {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        line-height: 30px;
        font-size: 18px;
        text-align: center;
    }

    .order_delivery_video video::-webkit-media-controls-panel,
    .order_delivery_video video::-webkit-media-controls-enclosure {
        display: none !important;
    }

    .home_slider_main_content {
        position: relative;
        /* So the text can be positioned relative to the image */
    }

    .home_left_kc_section {
        position: absolute;
        /* top: 346px; */
        bottom: 5px;
        left: 0;
        z-index: 10;
        padding: 13px;
        color: white;
        background-color: #9a1d2ea1;
        border-top-left-radius: 75px;
        border-top-right-radius: 75px;
    }

    .order_delivery_video {
        position: relative;
        z-index: 1;
    }

    .kc_album_section {
        width: 100%;
        bottom: -60px;
        left: 50%;
    }

    .quick_connect_section .row .quick_connect_item:last-child {
        border-right: none;
    }

    .quick_connect_section .row .quick_connect_item:last-child {
        border-right: non
    }

    .quick_cc {
        padding: 17px;
        height: 55vh;
    }

    .home_event_card {
        margin-right: 10px;
    }

    .side_tab_container {
        display: none;
    }

    .founder_message_all_text p {
        font-weight: 300;
        font-size: 16px;
        line-height: 23px;
        font-family: Poppins, sans-serif;
        margin-bottom: 0px;
        width: 100% !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 11;
        -webkit-box-orient: vertical;
    }

    /* .yellow .campus_middle_card_div {
        top: 76%;
    } */

    .campus_middle_card_div {
        top: 76%;
    }

    /* ///////// */
    .home_event_card:hover {
        background-color: inherit;

    }

    .event_card {
        background: #565656 !important;
        border-radius: 23px;
    }

    .home_event_card_active {
        background-color: #a23333 !important;
        transition: background-color 0.1s ease-in-out;
    }

    /* /////////////// */
    .home_diffrent_div {
        margin-right: 13px;
    }

    .home_sernior {
        height: 450px;
    }

    .home_diffrent_div {
        height: 485px !important;
    }

    /* ///////// for diffrent way to live */
    .diffrent_way_slide .home_diffrent_img_section::after {
        background: linear-gradient(to bottom, transparent, #9A1D2E);
    }

    .diffrent_way_slide .home_diffrent_img_section {
        filter: grayscale(0);
    }

    .diffrent_way_slide {
        background-color: #9A1D2E;
        color: #fff !important;
    }

    .diffrent_way_slide button {
        opacity: 1;
        transform: translateY(0);
        z-index: 9999;
    }

    .diffrent_way_slide .home_diffrent_text_section p {
        color: #fff !important;
    }

    .diffrent_way_slide .home_diffrent_text_section h5 {
        color: #fff !important;
    }

    .home_diffrent_text_section {
        padding: 0px 10px 15px 10px;
    }

    .order_delivery_img img,
    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        /* border-radius: inherit; */
        height: 80vh !important;
        border-radius: 0px 0px 0px 0px;
    }

    .dropdown_main_section {
        transform: translate3d(0px, 45px, 0px) !important;
        width: 88%;
        background-color: #606060;
        padding-inline: 17px;
        color: #fff !important;
        margin-left: 18px !important;
        border-bottom-left-radius: 11px !important;
        border-radius: 0;
        border-bottom-right-radius: 11px !important;
    }

    .order_delivery_main_img {
        border-radius: 0px 0px 0px 0px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        /* border-radius: inherit; */
        height: 80vh !important;
    }

    .home_all_button {
        display: inline-block;
        padding: 0.5rem 1rem;
        width: max-content;
        font-size: 14px;
    }

    .home_main_btn_mobile {
        justify-content: center;
    }

    .campus_own_gapping {
        padding-top: 60px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .campus_location .location_group {
        width: 32px !important;
    }

    .quick_padding_bottom {
        padding-bottom: 0 !important;

    }

    .quick_padding_top {
        padding-top: 0 !important;
    }

    .our_campus_map_section {
        display: none;
    }

    .campus_location_text li svg {
        width: 15px !important;
        height: 16px !important;
        padding: 4px;
    }

    .event_slider_limit h4 {
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        max-height: calc(1* 24px);
        line-height: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .event_slider_limit h5 {
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        max-height: calc(2* 24px);
        line-height: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .campus_middle_card_div ul {
        padding-left: 0;
        margin-bottom: 0px !important;
    }

    .event_slider_for_row .slick-slider ul {
        bottom: -25px !important;
        text-align: center !important;
    }

    .top_header_mobile_svg {
        margin-right: 4px;
    }

    .accreditations_image {
        margin-top: 1rem;
    }


}

.img-zoom {
    transform: scale(1.2);
}
