.career_section_gap {
    padding-left: 86px;
    padding-right: 86px;
    padding-top: 50px;
    padding-bottom: 0px;
}

.career_form h6 {
    font-family: "Poppins", sans-serif;
    color: #8D8D8D;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
}

.career_form h2 {
    font-family: graphik_medium;
    font-weight: 700;
    color: #000;
    font-size: 24px;
    line-height: 50px;
    margin-bottom: 0;
}

.career_form h4 {
    font-family: "Poppins", sans-serif;
    color: #383838;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0;

}

.career_form_section {
    margin-top: 40px;
}

.career_text_area {
    margin-top: 0 !important;
}

.career_hr {
    width: 98.5%;
    margin-left: 9px;
}

.career_upload_resume {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 25px;
}

.uplaod_resume_program {
    width: 50%;
}

.career_upload_resume label {
    padding: 6px !important;
}

.career_submit_btn {
    text-align: center !important;
}

.error-text {
    color: #c04e4e;
    font-size: 15px;
    text-align: left;
    margin-top: 0.25rem;
    font-family: "Poppins", sans-serif !important;
    margin-bottom: 0;
}

.text-danger {
    color: #c04e4e !important;
    font-size: 15px;
    text-align: left;
    margin-top: 0.25rem;
    font-family: "Poppins", sans-serif !important;
    margin-bottom: 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .career_section_gap {
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 140px;
    }
}


@media only screen and (max-width: 768px) {
    .career_section_gap {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 138px;
        padding-bottom: 0px;
    }

    .career_form_section {
        margin-top: 25px;
    }

    .career_form h2 {
        font-size: 22px;
    }

    .uplaod_resume_program {
        width: 100%;
    }

    .career_submit_btn {
        text-align: center !important;
        margin-top: 20px;
    }

    .error-text {
        color: #c04e4e;
        font-size: 15px;
        text-align: left;
        margin-top: 0.25rem;
        font-family: "Poppins", sans-serif !important;
        margin-bottom: 0;
    }

    .contact_us_hear {
        align-items: center;
        flex-direction: column;
    }
}
