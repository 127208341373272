.policy_expend_search input {
    border: none;
    border-bottom: 1px solid;
    border-radius: unset;
}

.policy_expend_search input::placeholder {
    color: #0f0202;

}

.policy_expend_search input:focus {
    border-color: unset;
    outline: none;
    box-shadow: none;
}

.policies_gapping_div {
    padding-left: 86px;
    padding-right: 86px;
    margin-top: 40px;
}

.kc_policies h2 {
    font-family: graphik_medium;
    font-weight: 600;
    font-size: 50px;
    line-height: 55px;

}

.kc_policies h2 span {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
    color: #a23333;
}

.kc_policies p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    line-height: 24px;
    font-size: 16px;
    color: rgba(87, 87, 87, 1);

}

.policies_span {
    color: rgba(0, 0, 0, 1) !important;

}

.policy_expend_search {
    text-align: right;
}

.policy_expend_search input::placeholder {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    line-height: 24px;
    font-size: 16px;
    color: rgba(87, 87, 87, 1);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .policies_gapping_div {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 140px;
    }

    .kc_policies h2 {
        font-size: 40px;
    }

    .kc_policies h2 span {
        font-size: 40px;
    }
}

@media only screen and (max-width: 768px) {
    .kc_policies h2 {
        font-size: 25px;
        line-height: 38px;
        margin-top: 90px;
    }

    .kc_policies h2 span {
        font-size: 25px;
        line-height: 51px;
    }
    .policy_expend_search{
        text-align: center;
    }
}
