.navbar-brand span {
    font-weight: bold;
    font-size: 1.25rem;
}

.navbar-nav .nav-link,
.navbar-nav .nav-dropdown {
    font-size: 1rem;
    color: #333;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-dropdown:hover {
    color: #a23333;
}

.navbar-nav .dropdown-item {
    color: #333;
}

.navbar-nav .dropdown-item:hover {
    background-color: #f8f9fa;
}

.all_fluid_main_class a {
    text-decoration: none;
    font-weight: 400;
    font-size: 17px !important;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
}

.all_fluid_main_class a:hover {
    color: #000;
}

.header_main_section {
    background-color: #a23333;

}

.header_main_logo {
    display: flex;
    flex-direction: row;
    gap: 10px;
    text-align: center;
    align-items: center;
}

.header_logo_text {
    text-align: start;
    margin-top: 15px;
}

.header_logo_text h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 29px;
    margin-bottom: 0;
    line-height: 15px;
    color: #a23333;
}

.header_logo_text h5 span {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 29px;
    margin-bottom: 0;
    line-height: 15px;
    color: #000;
}

.header_logo_text span {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 12px;
}

.all_fluid_main_class .header_top_text:hover {
    color: #fff !important;
}

.all_fluid_main_class_second {
    padding-left: 40px;
    padding-right: 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px 0px;
}

.all_fluid_main_class_second a {
    text-decoration: none;
    font-weight: 400;
    font-size: 17px !important;
    font-family: "Poppins", sans-serif;
    color: #000;
}

.all_fluid_main_class_second a:hover {
    color: #000 !important;
}

.header_dropdown_section span {
    color: #cabebe;
}

.header_custom_dropdown::after {
    display: none;
}

.header_title_name {
    color: #000 !important;
}

.header_title_name svg {
    margin-left: 3px;
    font-size: 25px;
    color: #cabebe;
}

/* /////////////// */
/* Open dropdown on hover */
.navbar-nav .dropdown:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
    padding: 10px;
}

/* Initially, the dropdown is hidden */
.navbar-nav .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

/* .header_expend_search{

} */
.header_expend_search input {
    border: none;
    border-bottom: 1px solid;
    border-radius: unset;
}

.header_expend_search input::placeholder {
    color: #fff;

}

.header_expend_search input:focus {
    border-color: unset;
    outline: none;
    box-shadow: none;
}

.main_mobile_view_nav_bar {
    display: none;
}

.main_desktop_view_nav_bar {
    z-index: +3;
    position: relative;
}

#about-dropdown .dropdown-menu {
    min-width: 150px !important;
    max-width: 150px;
    width: 150px;
}

.main_desktop_view_nav_bar nav {
    padding: 0 !important;
}

.sticky {
    position: fixed;
    top: 0;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: +999;
}

/* //////// About dropdown */
.dropdown_about .dropdown-menu {
    /* min-width: 80px !important; */
    width: max-content;
    /* max-width: 80px !important; */
    /* width: 117px !important; */
    border: none;
    background-color: #f8f9fa;
    /* top: 175% !important; */
    padding-bottom: 0;
    display: flex !important;
    flex-direction: column;
    gap: 8px;
    left: -3px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    margin-top: 0;
}

.dropdown_about {
    height: 65px;
    display: flex;
    text-align: center;
    align-items: center;
}

.dropdown_about .dropdown-menu a {
    padding: 0px 10px;
    /* padding-bottom: 4px; */
    font-size: 16px !important;
    padding-top: 3px;
    padding-bottom: 3px;
    /* padding-left: 3px; */
}

.dropdown_about .dropdown-menu a:hover {
    background-color: #9a1d2e;
    color: #fff !important;
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;

}

.dropdown_about .dropdown-menu a:hover .sub_heading_text {
    color: #fff !important;
}

/* //////// end About dropdown */
/* //////// Academics dropdown */
.dropdown_academic .dropdown-menu {
    min-width: 160px !important;
    border: none;
    background-color: #f8f9fa;
    /* top: 100% !important; */
    padding-bottom: 0;
    display: flex !important;
    flex-direction: column;
    gap: 5px;
    left: -5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    margin-top: 0;
}

.dropdown_academic {
    height: 65px;
    display: flex;
    text-align: center;
    align-items: center;
}

.dropdown_academic .dropdown-menu a {
    padding: 0px 10px;
    font-size: 16px !important;
    padding-top: 3px;
    padding-bottom: 3px;
}

.dropdown_academic .dropdown-menu a:hover {
    background-color: #9a1d2e;
    color: #fff !important;
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.dropdown_academic .dropdown-menu a:hover .sub_heading_text {
    color: #fff !important;
}

/* //////// end Academics dropdown */
/* //////// Admission dropdown */

.dropdown_admission .dropdown-menu {
    /* min-width: 145px !important; */
    /* max-width: 140px !important; */
    border: none;
    width: max-content;
    background-color: #f8f9fa;
    /* top: 90% !important; */
    /* width: 130px !important; */
    padding-bottom: 0;
    display: flex !important;
    flex-direction: column;
    gap: 5px;
    left: -5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    margin-top: 0;
}

.dropdown_admission {
    height: 65px;
    display: flex;
    text-align: center;
    align-items: center;
}

.dropdown_admission .dropdown-menu a {
    padding: 0px 10px;
    /* padding-bottom: 4px; */
    font-size: 16px !important;
    padding-top: 3px;
    padding-bottom: 3px;
}

.dropdown_admission .dropdown-menu a:hover {
    background-color: #9a1d2e;
    color: #fff !important;
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.dropdown_admission .dropdown-menu a:hover .sub_heading_text {
    color: #fff !important;
}

/* //////// end Admission dropdown */
/* //////// dropdown_news_and_events dropdown */

.dropdown_news_and_events .dropdown-menu {
    /* min-width: 250px !important; */
    /* max-width: 185px !important; */
    width: max-content;
    border: none;
    background-color: #f8f9fa;
    /* top: 90% !important; */
    padding-bottom: 0;
    display: flex !important;
    flex-direction: column;
    gap: 5px;
    left: -5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    margin-top: 0;
}

.dropdown_news_and_events {
    height: 65px;
    display: flex;
    text-align: center;
    align-items: center;
}

.dropdown_news_and_events .dropdown-menu a {
    padding: 0px 10px;
    /* padding-bottom: 4px; */
    font-size: 16px !important;
    padding-top: 3px;
    padding-bottom: 3px;
}

.dropdown_news_and_events .dropdown-menu a:hover {
    background-color: #9a1d2e;
    /* Red background on hover */
    color: #fff !important;
    /* White text on hover */
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.dropdown_news_and_events .dropdown-menu a:hover .sub_heading_text {
    color: #fff !important;
}

.header_custom_dropdown .dropdown-menu {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.3s ease;
}

.header_custom_dropdown.show .dropdown-menu {
    opacity: 1;
    max-height: 400px;
    transform: scaleY(1);
}

.sub_heading_text {
    padding-left: 0 !important;
    color: #333 !important;
}

.careeer_header {
    text-decoration: none;
    font-weight: 400;
    font-size: 17px !important;
    font-family: "Poppins", sans-serif;
    color: #000 !important;
}

.kc_header_after {
    position: relative;
    transition: color 0.3s ease;
}

.kc_header_after::after {
    content: "";
    position: absolute;
    left: 0;
    top: 25px;
    width: 0;
    height: 2px;
    background-color: #9a1d2e !important;
    transition: width 0.3s ease;
}

.kc_header_after:hover::after {
    width: 100%;
}

.kc_header_after_career {
    position: relative;
    transition: color 0.3s ease;
}

.kc_header_after_career::after {
    content: "";
    position: absolute;
    left: 0;
    top: 33px;
    width: 0;
    height: 2px;
    background-color: #9a1d2e !important;
    transition: width 0.3s ease;
}

.kc_header_after_career:hover::after {
    width: 100%;
}

/* .header_dropdown_section span:hover{
    color: #fff !important;
} */
/* .sticky_login{
    display: none;
} */
/* //////// end dropdown_news_and_events dropdown */
/* //////// dropdown_news_and_events dropdown */
@media (max-width: 1024px) and (min-width: 768px) {
    .header_expend_search input::placeholder {
        color: #fff;
        font-size: 14px;
    }

    .top_header_kc_alumni {
        display: block;
        margin-top: 184px;
        /* position: absolute; */
        right: 16px;
        padding: 0;
        background-color: #f8f9fa !important;
        min-width: 163px;
        overflow: hidden;
        padding: 10px;
    }

    .top_header_kc_alumni a:active {
        background-color: #fff;
        color: #000;
    }

    .top_header_kc_alumni a {
        border-bottom: 1px solid #e3d6d6;
    }

    .top_header_kc_alumni a:last-child {
        border-bottom: none;

    }

    .all_fluid_main_class a {
        text-decoration: none;
        font-weight: 400;
        font-size: 14px !important;
        font-family: "Poppins", sans-serif;
    }

    .top_header_mobile_svg svg {
        margin-right: 0px;
        cursor: pointer;
        font-size: 18px;
    }

    .header_use_svg {
        font-size: 15px !important;
    }

    .header_search_svg {
        font-size: 15px !important;
        margin-top: 4px;
    }

    .all_fluid_main_class_second {
        padding-left: 15px;
        padding-right: 15px;
    }

    .header_logo_text h5 {
        font-size: 20px;
    }

    .header_logo_text h5 span {
        font-size: 20px;
    }

    .header_main_logo {
        gap: 5px;
    }

    .header_logo_text {
        text-align: start;
        margin-top: 12px;
        line-height: 19px;
    }

    /* ////////////// */
    /* Initially hide the collapse content and place it off-screen in mobile view */
    .main_desktop_view_nav_bar {
        display: none;
    }

    /* .main_mobile_view_nav_bar {
        display: block;
        z-index: +9;
    } */

    /* /////////////// */
    .no-scroll {
        overflow: hidden;
        /* Prevent scrolling */
        height: 100%;
        /* Maintain full height */
    }

    .collapse-mobile-menu {
        position: fixed;
        top: 107px;
        right: -461px;
        width: 460px;
        height: 100vh;
        background-color: #f8f9fa;
        z-index: 9999;
        transition: transform 0.3s ease-in-out;
    }

    .collapse-mobile-menu.show {
        transform: translateX(-352px);
    }

    .header_main_logo {
        display: flex;
        align-items: center;
    }

    .header_main_logo img {
        margin-right: 10px;
    }

    .header_logo_text {
        display: flex;
        flex-direction: column;
    }

    .header_title_name {
        display: flex;
        align-items: center;
        cursor: pointer;
    }



    /* .navbar {
        padding: 10px;
    } */

    .navbar-light .navbar-toggler {
        border: none;
    }

    .navbar_rotate_180 {
        transform: rotate(180deg);
        /* Right side (default) */
        transition: transform 0.3s ease-in-out;
    }

    .navbar_rotate_0 {
        transform: rotate(0deg);
        /* Downward when open */
        transition: transform 0.3s ease-in-out;
    }

    /* //////////// */
    .header_dropdown_section {
        display: flex;
        flex-direction: column;
        text-align: start;
        justify-content: flex-start;
        padding-left: 18px;
        width: 100%;
    }

    .kc_mobile_header_main_section {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        gap: 35px;
        width: 100%;
        margin-top: 72px;
    }

    .kc_sub_header_sub_contentt {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        gap: 10px;
        /* background: #c6c6c626; */
        width: 100%;
        padding: 7px;
        border-radius: 10px;
        padding-top: 0;
        margin-bottom: 10px;
    }

    .kc_mobile_header_main_section li span {
        text-decoration: none;
        font-weight: 400;
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        color: #000;
    }

    .kc_header_sub_categories {
        font-size: 17px !important;
    }

    .kc_mobile_header_main_div {
        width: 96%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        gap: 10px;
        margin-bottom: 15px;
    }

    .kc_mobile_header_main_div span {
        flex: 1;
    }

    .kc_mobile_header_main_div svg {
        flex-shrink: 0;
    }

    .main_mobile_view_nav_bar .navbar {
        padding: 0;

    }


    .sticky_mobile {
        position: fixed !important;
        top: 52px !important;
        background-color: #fff;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        width: 100%;
    }

    .main_mobile_view_nav_bar {
        display: block;
        /* z-index: +9; */
        z-index: +5555;
        position: relative;
        top: 53px;
        position: fixed !important;
        width: 100%;
    }

    .header_main_section {
        position: fixed !important;
        width: 100%;
        top: 0px;
        z-index: +9999;
    }

    .header_tabs_view .collapse-mobile-menu {
        text-align: start;
        align-items: flex-start;
    }

    .kc_mobile_header_main_section li {
        border-bottom: 1px solid grey;
        width: 71%;
    }

    .mobile_header_top {
        display: none;
    }

}


@media (max-width: 768px) {
    .header_expend_search input::placeholder {
        color: #fff;
        font-size: 14px;
    }

    .top_header_kc_alumni {
        display: block;
        margin-top: 155px;
        /* position: absolute; */
        right: 16px;
        padding: 0;
        background-color: #f8f9fa !important;
        min-width: 163px;
        overflow: hidden;
        padding: 10px;
    }

    .top_header_kc_alumni a:active {
        background-color: #fff;
        color: #000;
    }

    .top_header_kc_alumni a {
        border-bottom: 1px solid #e3d6d6;
    }

    .top_header_kc_alumni a:last-child {
        border-bottom: none;

    }

    .all_fluid_main_class a {
        text-decoration: none;
        font-weight: 400;
        font-size: 14px !important;
        font-family: "Poppins", sans-serif;
    }

    .top_header_mobile_svg svg {
        margin-right: 0px;
        cursor: pointer;
        font-size: 18px;
    }

    .header_use_svg {
        font-size: 15px !important;
    }

    .header_search_svg {
        font-size: 15px !important;
        margin-top: 4px;
    }

    .all_fluid_main_class_second {
        padding-left: 17px;
        padding-right: 17px;
    }

    .header_logo_text h5 {
        font-size: 20px;
    }

    .header_logo_text h5 span {
        font-size: 20px;
    }

    .header_main_logo {
        gap: 5px;
    }

    .header_logo_text {
        text-align: start;
        margin-top: 12px;
        line-height: 19px;
    }

    /* ////////////// */
    /* Initially hide the collapse content and place it off-screen in mobile view */
    .main_desktop_view_nav_bar {
        display: none;
    }

    /* .main_mobile_view_nav_bar {
        display: block;
        z-index: +9;
    } */

    /* /////////////// */
    .no-scroll {
        overflow: hidden;
        /* Prevent scrolling */
        height: 100%;
        /* Maintain full height */
    }

    .collapse-mobile-menu {
        position: fixed;
        top: 107px;
        right: -200px;
        /* width: 354px; */
        width: 100%;
        height: 100vh;
        background-color: #f8f9fa;
        z-index: 9999;
        transition: transform 0.3s ease-in-out;

    }

    .collapse-mobile-menu.show {
        transform: translateX(-200px);
        /* border-top: 3px solid #b1b1b1 !important; */
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px 0px !important;
    }

    .header_main_logo {
        display: flex;
        align-items: center;
    }

    .header_main_logo img {
        margin-right: 10px;
    }

    .header_logo_text {
        display: flex;
        flex-direction: column;
    }

    .header_title_name {
        display: flex;
        align-items: center;
        cursor: pointer;
    }



    /* .navbar {
        padding: 10px;
    } */

    .navbar-light .navbar-toggler {
        border: none;
    }

    .navbar_rotate_180 {
        transform: rotate(180deg);
        /* Right side (default) */
        transition: transform 0.3s ease-in-out;
    }

    .navbar_rotate_0 {
        transform: rotate(0deg);
        /* Downward when open */
        transition: transform 0.3s ease-in-out;
    }

    /* //////////// */
    .header_dropdown_section {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        text-align: start;
        justify-content: flex-start;
        padding-left: 10px;
    }

    .kc_mobile_header_main_section {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 95%;
        margin-bottom: 0 !important;
    }

    .kc_sub_header_sub_contentt {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        gap: 2px;
        /* background: #c6c6c626; */
        width: 242px;
        padding: 7px;
        border-radius: 10px;
        padding-top: 0;
        margin-bottom: 10px;
    }

    .kc_mobile_header_main_section li span {
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        color: #000;
        padding-bottom: 0 !important;
    }

    .kc_header_sub_categories {
        font-size: 15px !important;
    }

    .kc_mobile_header_main_div {
        /* width: 95%; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        gap: 10px;
        margin-bottom: 10px;
    }

    .kc_mobile_header_main_div span {
        flex: 1;
    }

    .kc_mobile_header_main_div svg {
        flex-shrink: 0;
    }

    .main_mobile_view_nav_bar .navbar {
        padding: 0;

    }


    .sticky_mobile {
        position: fixed !important;
        top: 52px !important;
        background-color: #fff;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        width: 100%;
    }

    .main_mobile_view_nav_bar {
        display: block;
        /* z-index: +9; */
        z-index: +5555;
        position: relative;
        top: 53px;
        position: fixed !important;
        width: 100%;
    }

    .header_main_section {
        position: fixed !important;
        width: 100%;
        top: 0px;
        z-index: +9999;
    }

    .kc_mobile_header_main_section li {
        border-bottom: 1px solid #d8d5d5;
        width: 100%;
    }

    .kc_mobile_header_main_section li:last-child {
        border-bottom: none;
    }

    .mobile_header_top {
        background-color: #f4f0dd;
        width: 97%;
        border-radius: 10px;
        border-radius: 10px;
        padding: 14px 0px 7px 0px;
        position: relative;
    }

    /* .mobile_header_top::before {
        content: "";
        width: 8px;
        height: 70px;
        background-color: #f4f0dd;
        position: absolute;
        top: -66px;
    }

    .mobile_header_top::after {
        content: "";
        width: 8px;
        height: 70px;
        background-color: #f4f0dd;
        position: absolute;
        top: -66px;
        right: 0;
    } */

    /* .mobile_header_top {
        margin-top: 50px;
    } */

    /* .sticky_login{
        position: fixed !important;
        top: 0 !important;
        background-color: #fff;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        width: 100%;
    } */
     .mobile_view_subheading{
        padding-left: 0 !important;
     }
}
