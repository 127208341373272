.calendar_slider {
    background: #ebebeb;
    border-radius: 10px;
    margin-right: 14px;
    padding: 5px;
}

.calendar_slider_for_title {
    border-radius: 10px;
    overflow: hidden;
}

.calendar_slider_img {
    padding: 8px;
}

.calendar_slider:hover {
    background-color: #9a1d2e !important;
}

.calendar_slider_date {
    text-align: center;
    justify-content: center;
    align-items: center;
}

.calendar_slide_date h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 0;
    color: rgba(68, 68, 68, 1);
    padding: 8px 20px 3px 20px;
    text-align: center !important;
}

.calendar_slider:hover .calendar_slide_date h6 {
    color: #fff;
}

.calendar_label_text {
    text-align: center;
    align-items: center;
}

.calendar_label_text h4 .black {
    font-family: graphik_medium;
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    font-weight: 500;
}

.calendar_label_text h4 .danger {
    font-family: graphik_medium;
    color: #9a1d2e;
    font-size: 20px;
    font-weight: 500;
}

.calendar_label_text h5 {
    font-family: graphik_medium;
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 24px;
}

.calendar_label_text p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: rgba(87, 87, 87, 1);
    overflow: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 3;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 3px;
}


.calendar_beyond h2 {
    font-family: graphik_medium;
    font-weight: 600;
    font-size: 50px;
    line-height: 55px;
}

.calendar_beyond h2 span {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
    color: #a23333;
}

.calendar_beyond p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: rgba(87, 87, 87, 1);
}

.calendar_label_text h4 {
    margin-bottom: 3px;
}

.calendar_label_text h5 {
    margin-bottom: 3px;
}

.calendar_school_text {
    text-align: center !important;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.calendar_school_text h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
    color: rgba(0, 0, 0, 1);
}

.calendar_school_text h2 span {
    color: #a23333;
}

.calendar_school_text p {
    color: rgba(141, 141, 141, 1);
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    max-height: calc(93px);
    width: 43%;
}

.calendar_week {
    display: flex;
    gap: 15px;
}








.calendar-container {
    background-color: #f8f8f8;
    border-radius: 12px;
    padding: 15px;
    padding: 25px !important;
    margin-top: 35px;
}

.calendar_day-header {
    padding: 6px 44px;
    text-transform: uppercase;
    border-radius: 100px;
    text-align: center;
    background-color: #ffffff;
    color: rgba(87, 87, 87, 1);
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.calendar_day {
    height: 130px;
    background-color: white;
    border: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333;
    font-weight: 500;
    flex: 1;
}

.calendar_day:first-child {
    border-top-left-radius: 12px;
}

.calendar_day:nth-child(7) {
    border-top-right-radius: 12px;
}

.calendar_day:nth-last-child(7) {
    border-bottom-left-radius: 12px;
}

.calendar_day:last-child {
    border-bottom-right-radius: 12px;
}

.calendar_day span {
    color: rgba(87, 87, 87, 1);
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.calendar_day:hover {
    background-color: #f0f0f0;
}

.calendar_row_section {
    display: flex;
    flex-wrap: wrap;
}

.calendar_row_section .col {
    flex: 1 0 14.28%;
    max-width: 14.28%;
    padding: 5px;
    align-items: flex-start;
}

.calander_dot .slick-dots {
    left: -95% !important;
    top: 68px !important;
}

.calander_row {
    display: flex;
    justify-content: center;
}

.calnder_col {
    width: 80%;
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .calander_dot .slick-dots {
        left: -94.5% !important;
        top: 0px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .calendar_beyond h2 {
        font-size: 28px;
        line-height: 20px;
    }

    .calendar_beyond h2 span {
        font-size: 40px;
    }

    .calendar_school_text h2 {
        font-size: 40px;
        line-height: 55px;
    }

    .calendar_label_text h4 .danger {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .calendar_label_text h5 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .calander_beyone h2 br {
        display: none;
    }

    .calander_dot .slick-dots {
        left: -93% !important;
        top: -12px !important;
    }

    .calnder_col {
        width: 100% !important;
    }

    .calendar-container {
        padding: 2px !important;
    }

    .calander_beyone {
        margin-top: 40px;
    }

    .calendar_beyond h2 br {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .calander_beyone {
        text-align: center !important;
        margin-top: 96px !important;
    }

    .calendar_label_text h4 .danger {
        font-size: 16px;
    }

    .calendar_label_text h5 {
        font-size: 16px;
    }

    .calendar_school_text p {
        font-size: 16px;
        width: 100%;
    }

    .calnder_col {
        width: 100% !important;
    }

    .calendar-container {
        padding: 0 !important;
    }

    .clader_tg {
        padding: 10px !important;
    }

    .calendar_view_btn {
        margin-bottom: 30px
    }

    .calendar_slider:hover .calendar_slide_date h6 {
        color: #fff !important;
    }

    .calander_row {
        margin-top: 140px;
    }
}
