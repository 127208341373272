.schedule_main_text {
    margin-top: 10px !important;
}

.schedule_digital_sat {
    font-family: graphik;
    color: #101010;
    font-size: 22px;
    line-height: 20px;
    font-weight: 500;
    margin-top: 10px;
}

.schedule_test_date {
    margin-top: 22px;
}

.schedule_test_date .flex-row {
    display: flex;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    padding: 0px 6px;
    background-color: #767676;
    color: white;
    margin-bottom: 15px;
}

.schedule_test_date .flex-row .flex-cell {
    width: 33%;
    text-align: start;
    padding: 10px;
    text-align: left;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.schedule_date {
    background-color: #f0f0f0;
    text-align: start;
    padding: 10px 15px;
    text-align: left;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
    border-radius: 7px;
}

.schedule_date .flex-cell {
    width: 33%;
    padding: 0px 4px;
}

.exam_digital_psat .flex-row .flex-cell {
    width: 49% !important;
    padding: 10px 12px !important;
}

.exam_digital_psat span {
    width: 50% !important;
}

.course_assessment sup {
    top: 0 !important;
    font-family: graphik_bold;
    font-weight: 700 !important;
    font-size: 25px !important;
    line-height: 40px !important;
}



.schedule_college {
    background-color: #f8f4e2;
    border-radius: 8px;
    padding: 25px 53px !important;
    margin-top: 25px;
    padding-bottom: 33px !important;
}

.schedule_college h5 {
    font-family: graphik_bold;
    color: #101010;
    font-size: 24px;
    line-height: 20px;
    font-weight: 700;
}

.schedule_college h5 span {
    color: #9a1d2e !important
}

.schedule_college p {
    color: #515151 !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 15px !important;
}

.schedule_college button {
    border: none;
    background-color: #9a1d2e;
    color: #fff;
    padding: 8px 28px;
    border-radius: 59px;
    text-decoration: underline;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 15px;
}

.course_approximate {
    margin-top: 20px;
}

.course_approximate h1 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    color: #000;
    margin-bottom: 0;
}

.course_approximate h1 span {
    color: #a23333;
}

.course_approximate ul li {
    width: 100% !important;
    display: flex;
}

.course_approximate ul {
    padding-left: 10px;
}

.course_approximate ul li h4 span {
    margin-bottom: 9px;
    margin-right: 4px;
    font-weight: 900 !important;
}

.course_time {
    position: relative;
    display: flex;
    width: 22%;
    text-align: center;
    align-items: center;
    color: #8D8D8D !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 0px !important;
}

.course_staff {
    color: #8D8D8D !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 0px !important;
}

.course_document h1 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    color: #000;
    margin-bottom: 0;
}

.course_document h1 span {
    color: #a23333;
}

.course_document p {
    color: #8D8D8D !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 7px !important;
    width: 80%;
}

.course_document ul li {
    color: #8D8D8D !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 3px !important;
}

.schedule_further_visit {
    background-color: #f8f4e2;
    border-radius: 8px;
    padding: 22px 51px 5px 0px !important;
    margin-top: 25px;
}

.schedule_further_visit h5 {
    color: #515151 !important;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 14px;
}

.schedule_further_visit a {
    text-decoration: none;
}

.schedule_further_visit a p {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #9A1D2E;
    font-style: italic;
}

.schedule_baseline {
    border-radius: 7px;
    text-align: center;
    align-items: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.schedule_baseline h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #9A1D2E;

}

.schedule_baseline p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #6D6D6D;
    margin-top: 10px;

}

.schedule_baseline .card-body {
    padding: 18px;
}








@media only screen and (max-width: 768px) {
    .schedule_test_date .flex-row {
        text-align: start;
        align-items: flex-start;
        flex-direction: column !important;
    }

    .schedule_test_date .flex-row .flex-cell {
        width: 100%;
        font-weight: 300;
        font-size: 16px;
    }

    .schedule_date {
        flex-direction: column;
        text-align: start !important;
        align-items: flex-start !important;
        gap: 10px;
    }

    .schedule_date .flex-cell {
        width: 100%;
    }

    .schedule_digital_sat {
        font-size: 18px;
        line-height: 21px;
    }

    .course_assessment sup {
        top: 0 !important;
        font-size: 16px !important;
    }

    .schedule_college h5 {
        font-size: 18px;
        line-height: 28px;
    }

    .course_approximate h1 {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 15px;
    }

    .course_time {
        width: 100%;
    }

    .course_approximate ul li {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        margin-bottom: 12px !important;
    }

    .course_document h1 {
        font-size: 17px;
    }

    .schedule_further_visit a p {
        overflow: auto;
    }
    .schedule_further_visit {
        padding: 22px 0px 5px 0px !important;
    }
}
