.founder_section {
    background-color: #fffcec;
}

/* /////////////////// top slider */

/* ///////////////////////////////////////////////////// */
/* Slider Container */
.custom-slider-container {
    position: relative;
    padding: 20px 0;
}

/* Slider Items */
.founder_slider .slider-item {
    position: relative;
    transition: transform 0.5s, z-index 0.5s;
}

.founder_slider .slider-item.slick-active {
    transform: scale(1.1);
    /* Enlarge the active slide */
    z-index: 3;
    /* Bring it to the front */
}

.founder_slider .slider-item.slick-active~.slick-slide {
    transform: scale(0.9) translateX(30px);
    /* Slightly smaller and shifted */
    z-index: 2;
}

.founder_slider .slider-item {
    border-radius: 15px;
    overflow: hidden;
}

/* Right Slide */
.founder_slider .slick-slide:nth-of-type(3) {
    transform: scale(0.85) translateX(50px);
    /* Shrink and move it back */
    z-index: 1;
}

/* Image Styles */
.founder_slider .slider-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

/* Dots */
.founder_slider .slick-dots {
    position: absolute;
    bottom: 16px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.founder_slider .slick-dots li button {
    width: 9px;
    height: 9px;
    background-color: white;
    border-radius: 50%;
    transition: all 0.3s;
}

.founder_slider .slick-dots li.slick-active button {
    background-color: #a71d2a;
    width: 14px;
    height: 14px;
    border: 2px solid white;
}

/* ///////////////////////////////////////////////////// */
/* End Slider Container */
.founder_paragraph {
    width: 70% !important;

}

.founder_middle_img {
    position: relative;
    width: 100%;
    height: 67vh;
    margin-top: 15px;
}

.thumbnail-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.thumbnail {
    width: 100%;
    height: 68vh;
    filter: grayscale(1);
    border-radius: 10px;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgba(255, 255, 255, 0.8); */
    border: none;
    /* border-radius: 50%; */
    width: 100px;
    height: 100px;
    display: flex;
    background-color: unset;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    transition: transform 0.2s ease-in-out;
}

.play-button svg {
    color: red;
    font-size: 100px;
}


.video-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.video-iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.founder_education {
    background-color: #9A1D2E;
    padding: 20px 0;
    margin-top: 60px;
}

.reflection_sldier_img img {
    height: 70vh !important;
    overflow: hidden;
    width: 100%;
    height: auto;
    /* border-radius: 10px; */
    object-fit: cover;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.founder_reflication_slider_rght {
    position: absolute;
    top: 530px !important;
    right: -12px !important;
    background-color: #fff;
    color: #000;
    border: none;
    padding: 4px 12px;
    width: 36px;
    height: 36px;
    color: #000;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.founder_reflication_slider_left {
    position: absolute;
    top: 530px !important;
    right: 46px !important;
    background-color: #fff;
    color: #000;
    border: none;
    padding: 4px 12px;
    width: 36px;
    height: 36px;
    color: #000;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.founder_reflication_slider_left:active,
.founder_reflication_slider_rght:active {
    background-color: #9a1d2e;
    color: white;
}

.founder_reflication_slider_left:hover,
.founder_reflication_slider_rght:hover {
    background-color: #9a1d2e;
    color: white;
}

.founder_reflication_slider_rght svg {
    font-size: 13px;
}

.founder_reflection_gapping_section {
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 1px;
    margin-top: 65px;
}

.domian_slider_dot .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    left: -97%;
    top: 37px;
    list-style: none;
    text-align: center;
}

.reflection_slider_dot .slick-dots li button::before {
    font-size: 10px;
    color: rgb(164, 164, 164) !important;
    opacity: 1;
    top: 9px !important;
    left: -397px !important;
}

.reflection_slider_dot .slick-dots li.slick-active button::before {
    color: #a23333 !important;
}

.founder_row {
    margin-left: 0;
    height: 100%;
}

.founder_col {
    border: 3px solid #e6e6e6;
    height: 100%;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
}


.founder_text {
    padding: 35px;
    height: 69.5vh;
    text-align: center;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.founder_text h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: rgba(0, 0, 0, 1);

}

.founder_text p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: rgba(141, 141, 141, 1);
    line-height: 32px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: rgba(141, 141, 141, 1);
    line-height: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reflection_sldier_img {
    padding-left: 0;
}
















/* //////////////////////////////  slider*/

/* THREE DAY ma'am csss started here*/
.founder_slider_container {
    height: 26rem;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: relative;
    margin-top: 2rem;
}

/* .slider-title {
    position: absolute;
    top: 0;
    font-family: 'Papyrus', Fantasy;
    font-size: 20px;
} */

/* .left-arrow,
.right-arrow {
    height: 100%;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.founder_slider_container .slider-content {
    height: 100%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    perspective: 100px;
}

.founder_slider_container .slider-content-background {
    height: 100%;
    width: 102%;
    position: absolute;
    top: 0;
    left: -1%;
    background: linear-gradient(to left,
            #f1f1f1,
            transparent,
            transparent,
            transparent,
            #f1f1f1);
    z-index: 3;
}

.founder_slider_container .slider-content .slide {
    position: absolute;
    left: 50%;
    height: 24rem;
    max-height: 400px;
    width: 20rem;
    min-width: 270px;
    background: #999;
    /*   border: 1px solid; */
    border-radius: 25px;
    left: 50%;
    z-index: 0;
    /*   opacity: 0; */
    transform: translate(-50%, 0) rotateY(0deg) scale(1, 1);
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, left 0.5s ease-in-out,
        z-index 0s 0.25s ease-in-out, box-shadow 0.5s ease-in-out, filter 0.5s ease-in-out;
}

.founder_slider_container .slider-content .slide.position-1 {
    left: 61% !important;
    z-index: 1 !important;
    transform: translate(-50%, 0) rotateY(-2deg) scale(0.8, 0.8) !important;
    /* opacity: 1 !important;
    box-shadow: 0px 0.4rem 1.6rem rgba(0, 0, 0, 0.1) !important; */
    /* filter: blur(5px); */

}

.founder_slider_container .slider-content .slide.position-2 {
    left: 57% !important;
    z-index: 2 !important;
    transform: translate(-50%, 0) rotateY(-1deg) scale(0.9, 0.9) !important;
    opacity: 1 !important;
    /* box-shadow: 0px 0.4rem 1.6rem rgba(0, 0, 0, 0.3) !important;
    filter: blur(2px); */

}

.founder_slider_container .slider-content .slide.position-3 {
    left: 50% !important;
    z-index: 4 !important;
    transform: translate(-50%, 0) rotateY(0deg) scale(1, 1) !important;
    opacity: 1 !important;
    /* box-shadow: 0px 0.4rem 1.6rem rgba(0, 0, 0, 0.5) !important; */
    cursor: pointer;
    /* filter: blur(0px); */
}

.founder_slider_container .slider-content .slide.position-3:hover {
    /* box-shadow: 0px 0rem 1.8rem rgba(0, 0, 0, 0.7) !important; */
    transform: translate(-50%, 0) rotateY(0deg) scale(1.05, 1.05) !important;
}

.founder_slider_container .slider-content .slide.position-4 {
    left: 65% !important;
    z-index: 2 !important;
    transform: translate(-50%, 0) rotateY(1deg) scale(0.9, 0.9) !important;
    opacity: 1 !important;
    /* box-shadow: 0px 0.4rem 1.6rem rgba(0, 0, 0, 0.3) !important; */
    /* filter: blur(2px); */

    /*   animation: center-animation 1s 1s linear 1 forwards; */
}

.founder_slider_container .slider-content .slide.position-5 {
    left: 80% !important;
    z-index: 1 !important;
    transform: translate(-50%, 0) rotateY(2deg) scale(0.8, 0.8) !important;
    opacity: 1 !important;
    /* box-shadow: 0px 0.4rem 1.6rem rgba(0, 0, 0, 0.1) !important; */
    /* filter: blur(5px); */


}

.founder_slider_container .slider-content .slide.position-none {
    left: 50%;
    z-index: 0;
    transform: translate(-50%, 0) rotateY(0deg) scale(0.7, 0.7);
    opacity: 1;
    /* box-shadow: 0px 0.4rem 1.6rem rgba(0, 0, 0, 0); */
}

.founder_slider_container i {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
}

.founder_slider_container .left-arrow:hover i,
.founder_slider_container .right-arrow:hover i {
    background: rgba(22, 22, 220, 0.1);
    color: #fff;
}


.founder_slider_container .slider-content .slide .card-sections .upper-section,
.founder_slider_container .slider-content .slide .card-button {
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
}

.founder_slider_container .slider-content .slide.position-3 .card-sections .upper-section,
.founder_slider_container .slider-content .slide.position-3 .card-button {
    opacity: 1;
    transition: opacity 0.6s ease-in-out;
}

.founder_slider_container .slider-content .slide>* {
    color: white;
    font-family: 'Inter';
    font-size: 90%;
    letter-spacing: -0.001em;
}

.founder_media_img,
.card-sections {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    overflow: hidden;
}

.founder_media_img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.founder_media_img img {
    position: absolute;
    height: 30rem;
}

.card-sections {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}

.founder_slider_container {
    position: relative;
    overflow: hidden;
}

.slider-content {
    display: flex;
    justify-content: center;
}

.founder_slider_container .slider-content .slide {
    flex: 0 0 80%;
    opacity: 0.5;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.founder_slider_container .slider-content .slide.position-3 {
    opacity: 1;
}

/* .left-arrow,
.right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
} */

/* .left-arrow {
    left: 10px;
}

.right-arrow {
    right: 10px;
} */

.slide_dot_founder {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: absolute;
    z-index: 999999;
    bottom: 42px;
}

.dot_founder {
    height: 12px;
    width: 12px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dot_founder.active {
    background-color: #333;
}

.founder_section_adjust {
    margin-bottom: 17px !important;
}

.reflection_side_mobile {
    margin-bottom: 30px !important;
}

.reflection_right {
    top: 460px !important;
    display: none;
}

.reflection_left {
    top: 460px !important;
    display: none;
}
/* THREE DAY ma'am csss end here*/
@media (min-width: 1400px) and (max-width: 1500px) {
    .reflection_right {
        top: 460px !important;
        display: none;
    }

    .reflection_left {
        top: 460px !important;
        display: none;
    }
}

@media (min-width: 1300px) and (max-width: 1400px) {
    .reflection_right {
        top: 460px !important;
        display: none;
    }

    .reflection_left {
        top: 460px !important;
        display: none;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {
    .founder_slider_container {
        height: 26rem;
    }

    .reflection_slider_dot .slick-dots li button::before {
        font-size: 10px;
        color: rgb(164, 164, 164) !important;
        opacity: 1;
        top: 9px !important;
        left: -312px !important;
    }

    .reflection_right {
        top: 460px !important;
        display: none;
    }

    .reflection_left {
        top: 460px !important;
        display: none;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .reflection_slider_dot .slick-dots li button::before {
        left: -149px !important;
    }
}
@media only screen and (max-width: 768px) {
    .meet_the_founder_mobile {
        margin-top: 138px !important;
    }

    .meet_the_founder_mobile p {
        margin-bottom: 0;
    }

    .founder_slider_container {
        margin-top: 0;
        height: 27rem;
    }

    .founder_slider_container .slider-content {
        width: 100%;
    }

    .founder_slider_container .slider-content .slide.position-3 {
        left: 44% !important;
    }

    .founder_slider_container .slider-content .slide.position-1 {
        left: 64% !important;
    }

    .founder_slider_container .slider-content .slide.position-2 {
        left: 55% !important;
    }

    .policies_gapping_div {
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 40px;
    }

    .calendar_school_text h2 {
        font-size: 25px;
        line-height: 40px;
    }

    .founder_paragraph {
        width: 100% !important;
    }

    .founder_middle_img {
        height: 32vh;
        margin-top: 68px;
    }

    .founder_reflection_gapping_section {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 1px;
        margin-top: 16px;
    }

    .calendar_beyond h2 {
        font-size: 25px;
        line-height: 35px;

    }

    .calendar_beyond h2 span {
        font-size: 25px;
        line-height: 20px;
    }

    .reflection_side_mobile {
        margin-top: 27px !important;
    }

    .founder_col {
        border: none;
        height: 100%;
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
        display: none;
    }

    .founder_text {
        padding: 0px;
    }

    .reflection_slider_dot ul li button {
        background: #ccc;
        border-radius: 50px;
        width: 13px;
        height: 13px;
    }

    .reflection_slider_dot ul li.slick-active button {
        background: #9A1D2E;
        /* Change active dot to red */
    }

    .founder_reflication_slider_rght {
        display: none
    }

    .founder_reflication_slider_left {
        display: none;
    }

    .slide_dot_founder {
        bottom: 55px;
        z-index: 99;
    }

    .founder_mobile_padding {
        padding-right: 10px;
    }

    .reflection_sldier_img img {
        height: 55vh !important;
        border-radius: 15px !important;
    }

    .reflection_sldier_img {
        padding: 19px !important;
        padding-left: 6px !important;
    }
    .refelection_founder{
        padding-left: 15px !important;
        padding-right: 15px;
    }
}
