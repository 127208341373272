/* src/components/Footer.css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


.footer {
    background-color: #232323;
    color: #fff;
}

.footer_main_section {
    justify-content: space-between;
}

.footer a {
    color: #fff;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

.kc_footer_main_section {
    padding-top: 2rem;
    /* background-color: #f4f0dd; */
    border-top-left-radius: 78px;
    border-top-right-radius: 78px;
    margin-top: 2rem !important;
    background-color: rgba(244, 240, 221, 1);
}

/* .newsletter_main_section {
    position: absolute;
    top: -118px;
    width: 100%;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

.news_letter_main_section_div {
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
}

.newsletter_main_section .social-icons i {
    font-size: 18px;
}

.frisbee_project_veternary {
    margin-top: 35px;
}

.kc_bottom_text p {
    margin-bottom: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding: 10px;
    color: #000;
    background: rgba(235, 230, 204, 1);
    color: rgba(56, 56, 56, 1)
}

.frisbee_up_to_date_for_news {
    display: flex;
    flex-direction: column;
}

.frisbee_up_to_date_for_news span {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: "Poppins", sans-serif;
    color: #fff;
}

.frisbee_up_to_date_for_news h5 {
    font-family: "Poppins";
    font-size: 23px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 0;
    color: #fff;
}

/* /// */
.frisbee_up_to_date_main_div {
    width: 45%;
    justify-content: space-between;
}

.frisbee_up_to_date_main_div .form-inline {
    width: 66%;
}

.inputNewsletter {
    position: relative;
}

.inputNewsletter input {
    width: 100%;
    padding: 8px 16px;
    border-radius: 100px;
    border: 1px solid #d6cdcd;
    outline: none;
    background: #f8f8f8;
    font-size: 16px;
}

.inputNewsletter button {
    color: #000;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    font-family: "Poppins";
}

.inputNewsletter button:hover {
    background: #f8f8f8;
}

.footer-btn {
    position: absolute;
    right: 0;
    border-radius: 100px;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px 23px;
    color: #000;
    transition: all 0.3s ease-in-out;
    background: #efefef;
}

.frisbee_all_footer_icon {
    gap: 5px;
}

.frisbee_all_footer_icon a {
    background: #64131e;
    padding: 7px;
    width: 35px;
    border-radius: 50%;
    height: 35px;
    text-align: center;
    align-items: center;
    display: flex;
    color: #fff !important;
}

.frisbee_all_footer_icon a svg {
    font-size: 18px;
    margin-left: 2px;
}

.newsletter_main_section {
    padding: 10px;
    border-radius: 100px;
    width: 100%;
    background-color: #9a1d2e !important;
}

.all_kc_footer h5 {
    font-family: "Poppins";
    font-size: 22px;
    line-height: 29px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
}

.all_kc_footer ul li a {
    font-weight: 400;
    font-size: 16px;
    color: rgba(80, 80, 80, 1) !important;
    text-decoration: none !important;
    font-family: "Poppins";
    line-height: 28px;
}

.all_kc_footer ul li {
    font-weight: 400;
    font-size: 16px;
    /* color: #000; */
    color: rgba(54, 54, 54, 1) !important;
    text-decoration: none !important;
    font-family: "Poppins";
}

.all_kc_footer ul li {
    font-weight: 400;
    font-size: 16px;
    /* color: #000; */
    color: rgba(54, 54, 54, 1) !important;
    text-decoration: none !important;
    font-family: "Poppins";
}

.kc_all_icon_for_location li svg {
    background: #fff;
    padding: 7px;
    width: 34px;
    border-radius: 50%;
    height: 33px;
    margin-right: 4px;
    color: #000;
}

.all_kc_footer {
    text-align: start;
}

.addrees_footer li {
    display: flex;
    margin-bottom: 10px;
}

.footer_details {
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.hk_up_to_date_for_news {
    text-align: start;
    margin-left: 10px;
}

.hk_up_to_date_for_news h5 {
    font-size: 25px;
    /* color: #fff; */
    margin-bottom: 0;
    font-family: "Poppins";
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
}

.hk_up_to_date_for_news p {
    font-size: 13px;
    /* color: #fff; */
    margin-bottom: 0;
    font-family: "Poppins";
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
}

.all_fluid_main_class {
    padding-left: 55px;
    padding-right: 55px;
}

ul.all_footer_content li a {
    text-decoration: none;
    color: white;
    position: relative;
    display: inline-block;
    transition: color 0.3s ease, transform 0.3s ease;
}

/* Hover effect */
ul.all_footer_content li a:hover {
    color: #9a1d2e !important;
    transform: translateX(10px);
}

.footer_logo_img img {
    width: auto;
    height: 43px;
}

.footer_location_text svg {
    width: 72px !important;
    height: 34px !important;
}

.kc_footer_address {
    font-family: "Open Sans", sans-serif !important;
}

.progress-circle {
    position: fixed;
    bottom: 1px;
    right: 10px;
    width: 60px;
    height: 60px;
    z-index: 1000;
}

/* SVG circle styling */
/* .progress-ring__circle {
    transition: stroke-dashoffset 0.2s ease-out;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
} */

/* Back-to-top button */
/* .back-to-top {
    align-items: center;
    background: #fff1f0;
    border: none;
    border-radius: 50%;
    bottom: 16px;
    color: #9a1d2e;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    height: 40px;
    justify-content: center;
    position: fixed;
    right: 16px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    width: 40px;
    z-index: 1000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.back-to-top:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .back-to-top {
        font-size: 18px;
        height: 36px;
        width: 36px;
        bottom: 12px;
        right: 12px;
        z-index: 9999 !important;
    }

    .back-to-top svg {
        font-size: 20px !important;
    }

    .top_icon {
        font-size: 20px !important;
    }

}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .back-to-top svg {
      display: block;
      width: 20px !important; 
      height: 20px !important;
    }
  } */
.back-to-top {
    align-items: center;
    background: #fff1f0;
    border: none;
    border-radius: 50%;
    bottom: 16px;
    color: #9a1d2e;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: fixed;
    right: 16px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    width: 40px;
    z-index: 1000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    /* Ensure the arrow scales consistently */
}

.back-to-top:hover {
    transform: scale(1.1);
    background: #9a1d2e;
    color: #fff1f0;
}

/* Custom Arrow Icon */
.top_icon {
    font-size: 20px;
    /* Adjust icon size */
    font-weight: bold;
    line-height: 1;
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
    .back-to-top {
        font-size: 18px;
        height: 36px;
        width: 36px;
        bottom: 12px;
        right: 12px;
    }

    .top_icon {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1441px) and (min-width: 1024px) {
    .frisbee_up_to_date_main_div {
        width: 55%;
        justify-content: space-between;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .kc_footer_main_section {
        border-top-left-radius: 52px;
        border-top-right-radius: 52px;
    }

    .footer_details {
        padding-left: 10px;
        padding-right: 22px;
    }

    .footer_location_text svg {
        width: 90px !important;
        height: 34px !important;
    }
}

@media only screen and (max-width: 768px) {
    .footer_details {
        flex-direction: column;
    }

    .all_kc_footer {
        /* border: 1px solid #fff; */
        /* border-radius: 10px; */
        padding-bottom: 2px;
        padding-top: 10px;
        border-top: 1px solid #cfccbc;
        text-align: center;
    }

    .addrees_footer li {
        display: flex;
        margin-bottom: 10px;
    }

    .all_kc_footer h5 {
        display: flex;
        width: 100%;
        text-align: center;
        font-size: 16px;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
        margin-bottom: 0;
        font-weight: 400;
        /* margin-bottom: 10px; */
    }

    .frisbee_mobile_view_row_footer {
        display: flex;
        gap: 10px;
        padding-inline: 10px;
    }

    .all_kc_footer ul li a {
        font-size: 14px;
    }

    .mobile_view_frisbee_footer_for_all_main {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .mobile_view_frisbee_footer_for_all_main a {
        text-decoration: none !important;
    }

    .frisbee_mobile_for_pic {
        padding-left: 0;
        padding-right: 0;
    }

    .frisbee_up_to_date_main_div {
        width: 100%;
        justify-content: space-between;
    }

    .frisbee_up_to_date_main_div .form-inline {
        width: 100%;
    }

    .inputNewsletter button {
        font-size: 14px;
    }

    .inputNewsletter input {
        font-size: 14px;
    }

    .frisbee_all_footer_icon {
        gap: 5px;
    }

    /* .frisbee_mobile_for_pic {
        gap: 5px;
    } */

    .newsletter_main_section {
        top: -135px;
    }

    .kc_bottom_text p {
        font-size: 16px;
    }

    .all_kc_footer ul li {
        font-size: 14px;
        background-color: #fff;
        padding: 6px 12px;
        border-radius: 10px;
    }

    .kc_all_icon_for_location li svg {
        width: 22px;
        height: 22px;
        padding: 4px;
    }

    .all_kc_footer ul {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }

    .inputNewsletter {
        position: relative;
        display: none;
    }

    .school_footer_div {
        display: none;
    }

    .hk_up_to_date_for_news h5 {
        font-size: 18px;
    }

    .hk_up_to_date_for_news p {
        font-size: 12px;
    }


    .news_letter_logo_for_mobile img {
        width: 35px;
        height: 35px;
        display: none;
    }

    .kc_footer_main_section {
        padding-top: 1rem;
        background-color: #f4f0dd;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        margin-top: 2rem !important;
    }

    .all_kc_footer h5 svg {
        font-size: 14px;
        font-weight: 300;
    }

    .news_letter_main_section_div {
        padding-inline: 10px;
    }

    .frisbee_all_footer_icon a {
        padding: 9px;
        width: 31px;
        height: 33px;
    }
}