.academics_all_section {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 60px;
}

.discover_content_p {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.early_circular_image {
    padding: 5%;
    width: 100%;
    max-width: 514px;
    min-height: 200px;
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 50%;
    overflow: hidden;
    /* Ensures the image doesn't spill out */
}

.discover_content_p:hover,
.academic_slider_cotnet:hover .discover_content_p {
    visibility: visible;
    opacity: 1;
}

.home_diffrent_main_section a,
.explore_slider_main_div a {
    text-decoration: none;
}

.board_Section img {
    height: auto;
    width: 123px;
}

.sec_adjust {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;
}

.early_years_section {
    background-image: linear-gradient(#ffffff, #fff9e2);
    padding-bottom: 55px;
}

.early_top_section {
    text-align: center;
    display: flex;
    justify-content: start;
    align-items: center;
}

.early_year_heading_Section {
    margin-left: 43px;
    padding: 0 40px;
}

.early_year_heading_Section h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 70px;
    line-height: 90px;
    color: #a23333 !important;
}

.early_year_heading_Section h2 span {
    color: #000 !important;
}

.early_year_heading_Section h4 {
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
}

.early_year_heading_Section h4 span {
    color: #a23333 !important;
    font-weight: 600;
}

.early_year_heading_Section p {
    font-weight: 400;
    font-size: 19px;
    line-height: 34px;
    font-family: "Poppins", sans-serif;
    color: rgba(87, 87, 87, 1);
}

.image-section {
    position: relative;
    text-align: center;
    padding: 5px;
    margin-bottom: 46px;
}



.all_tabs_section {
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.early_tabs_container {
    display: flex;
    background-color: #f4f0dd;
    padding: 10px;
    border-radius: 100px;
    justify-content: space-between;
    width: 65%;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.early_tabs_container .tab-item {
    flex: none;
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    border-radius: 100px;
    background-color: white;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    width: 151px;
}

.early_tabs_container .tab-item.active {
    background-color: #a23333;
    color: white;
}

/* .early_tabs_container .tab-item:hover {
    background-color: #e0e0e0;
} */
.tadpoles_div {
    padding-left: 200px;
    padding-right: 200px;
}

.early_tab_content {
    margin-top: 30px;
}

.early_tabs_img_section img {
    width: auto;
    height: 53vh;
    object-fit: cover;
}

.early_tabs_tadpoles_div {
    /* margin-top: 15px; */
    padding-left: 0;
    margin-left: 10px;
}

.early_tabs_tadpoles_div h2 {
    font-family: graphik_bold;
    margin-bottom: 15px;
    font-size: 43px;
}

.early_tabs_tadpoles_div h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: rgba(74, 74, 74, 1);
}

.early_tabs_tadpoles_div p {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    font-family: "Poppins", sans-serif;
    color: #666666;
}

/* .early_read_btn {
    width: 20% !important;
} */

.home_founder_sub_image {
    padding-left: 0;
    padding-right: 0;
}

/* .early_faq_container{
    width: 100%;
}
.early_faq_container .faq-item{
    padding: 12px 16px;
    border: 1px solid #C2B8B5; 
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s ease;
}
.early_faq_container .faq-item.active {
    background-color: #931A23; 
    color: #fff;
  }
  .early_faq_container .faq-content{

  } */
.academic_early_message {
    background-color: #9A1D2E;
    padding: 20px 0;
    display: flex;
}

.academics_button {
    display: inline-block;
    padding: 10px 27px;
    border-radius: 10rem;
    color: #fff;
    font-size: 16px;
    /* letter-spacing: 0.1rem; */
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    width: max-content;
    font-weight: 600;
}

.academics_button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #9a1d2e;
    border-radius: 10rem;
    z-index: -2;
    border: 1px solid #fff;
}

.academics_button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #fff;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
}

.academics_button:hover {
    color: #000;
}

.academics_button:hover:before {
    width: 100%;
}

.academics_ready_text {
    font-size: 36px !important;
    color: #fff;
    margin-bottom: 0;
    font-family: graphik !important;
    /* font-family: graphik_light !important; */
}

.together {
    width: 100% !important;
}

.early_slider_img {
    width: 100%;
}

.learn_article_slider_main_section {
    margin-right: 20px;
    /* border: 1px solid #dfdede !important; */
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    height: 280px;
}

.learn_article_slider_main_section:hover .card-title {
    color: #9a1d2e;
    transition: color 0.3s ease;
}

.early_slider_img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}

.explore_slider_main_div .slick-slider {
    position: relative;
}

.home_slider_left_right_button {
    /* position: absolute;
    top: 410px;
    left: -10px;
    width: 36px;
    height: 36px;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: background-color 0.3s ease; */
    position: absolute;
    top: 108%;
    left: 42px;
    transform: translateY(-50%);
    background-color: #fff;
    color: #000;
    border: none;
    padding: 4px 12px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.home_slider_left__button {
    /* position: absolute;
    top: 410px;
    left: 42px;
    background-color: #fff;
    color: #000;
    border: none;
    padding: 4px 12px;
    width: 36px;
    height: 36px;
    color: #000;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: background-color 0.3s ease; */
    position: absolute;
    top: 108%;
    left: 42px;
    transform: translateY(-50%);
    background-color: #fff;
    color: #000;
    border: none;
    padding: 4px 12px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.home_slider_left_right_button:active,
.home_slider_left__button:active {
    background-color: #9a1d2e;
    color: white;
}

.home_slider_left_right_button:hover,
.home_slider_left__button:hover {
    background-color: #9a1d2e;
    color: white;
}

.home_slider_left_right_button svg {
    font-size: 13px;
}

.academic_early_store {
    align-items: flex-end;
    margin-right: -10px;
}

.academic_early_store button {
    /* width: 13% !important; */
    margin-top: 10px;
}

.learn_article_slider_main_section .date-badge {
    /* position: absolute;
    top: 200px;
    left: -1px;
    background-color: #b72126;
    color: #fff;
    padding: 5px 10px;
    font-size: 13px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    font-weight: 400;
    font-family: "Poppins", sans-serif; */
    position: absolute;
    top: 149px;
    left: -1px;
    transform: translateY(-50%);
    background-color: #b72126;
    color: #fff;
    padding: 5px 10px;
    font-size: 13px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;

}

.subscription_slider_main_div {
    padding: 15px;
}

.subscription_slider_main_div .card-title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: rgba(0, 0, 0, 1);
    text-overflow: ellipsis;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    max-height: calc(1 * 24px);
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.subscription_slider_main_div .card-text {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #8D8D8D;
    overflow: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    max-height: calc(2 * 24px);
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.academics_faq_section {
    margin-top: 40px;
}

.early_faqs_heading button {
    background-color: #fff !important;
    border: 1px solid #9a1d2e;
    border-radius: 5px !important;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: #222222 !important;
    line-height: 20px !important;
    padding: 10px 19px;
}

.early_faqs_heading button:focus {
    box-shadow: none;
}

.early_faqs_paragraph {
    background-color: #fffcec;
    border: 1px solid red;
    border-top: none;
    line-height: 7p;
    line-height: 21px;
    text-align: start;
    padding: 10px 19px;
}

.academic_slider {
    overflow: hidden;
    border-radius: 15px;
    margin-right: 10px;

}


.academic_slider::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: unset;
    z-index: 1;
    background: linear-gradient(180deg, rgba(35, 35, 35, 0) 66.63%, #232323 100%);
}

.academic_slider:hover .academic_slider_cotnet {
    top: 0%;
    background: #9a1d2ebd;
    animation: slideUp 0.8s ease-in-out forwards;
}

.academic_slider:hover ul li span p {
    color: #fff;
}

/* .academic_slider:hover .home_school_heading p {
    color: #fff;
} */

.kids_central {
    font-family: graphik_bold;
    font-size: 30px;
    font-weight: 600;
    line-height: 20px;
}

.cool_cats {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 7px;
    padding: 5px 2px;
    padding-bottom: 15px;
}

.academic_slider_cotnet {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
}

/* .academic_slider_cotnet {
    z-index: 99;
    top: 75%;
    width: 100%;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
} */
.academic_slider_cotnet {
    z-index: 99;
    top: 50%;
    width: 100%;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: translateY(26%);
}

.academic_slider_cotnet ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 85px;
}

.board_Section {
    width: 80%;
}

.board_Section_text h2 {
    font-family: graphik_bold;
    font-size: 32px;
}

.board_Section_text p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    color: rgba(255, 255, 255, 1) !important;
}

/* Detail styling */
.details {
    --padding: 9px;
    /* border: 1px solid #f4d1d6; */
    border-radius: 6px;
    margin-top: 40px;
    background-color: #fff;
    overflow: hidden;
}

.details .open {
    border: 1px solid #9a1d2e;
}

.details .open:first-child {
    border-bottom: none;
}

.details+.details {
    margin-top: 15px;
}

.faq_details__title {
    --width: 20px;
    --height: 2px;
    --radius: 1px;
    --color: #9a1d2e;
    margin: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    position: relative;
    font-family: "Poppins", sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 40px !important;
    text-align: start;
    padding: 5px 20px;
}

/* .faq_details__title:before,
.faq_details__title:after {
    content: '';
    display: block;
    width: var(--width);
    height: var(--height);
    background-color: var(--color);
    position: absolute;
    border-radius: var(--radius);
    top: calc(50% - (var(--height) / 2));
    right: calc(var(--width) / 2);
    transition: all 0.2s ease-in-out;
    width: 14px;
} */
.faq_details__title:before,
.faq_details__title:after {
    content: '';
    display: block;
    width: var(--width);
    height: var(--height);
    background-color: var(--color);
    position: absolute;
    border-radius: var(--radius);
    top: calc(50% - (var(--height) / 2));
    right: calc(var(--width) / 2);
    transition: all 0.2s ease-in-out;
    width: 14px;
}

.faq_details__title:after {
    rotate: 90deg;
}

.details__container.open .faq_details__title:before {
    transform: rotate(180deg);
    /* Turns "+" into "−" */
}

.details__container.open .faq_details__title:after {
    opacity: 0;
}

/* .faq_details__title:after {
    rotate: 90deg;
} */

.details__container.open .faq_details__title {
    background-color: #9a1d2e;
    --color: rgba(255, 255, 255, 1);
    border-radius: 0px;
    color: rgba(255, 255, 255, 1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.details__desc {
    visibility: hidden;
    display: grid;
    grid-template-rows: 0fr;
    transition: max-height 0.4s ease, padding 0.4s ease !important;
    overflow: hidden;
    padding-inline: var(--padding);
    pointer-events: none;
}

/* .details__container.open+.details__desc {
    visibility: visible;
    grid-template-rows: 1fr;
    padding-block: var(--padding);
    text-align: start;
    padding: 7px 22px;
    font-size: 16px;
    font-family: "Poppins", sans-serif !important;
    background-color: #fffcec;
} */
.details__container.open+.details__desc {
    visibility: visible;
    grid-template-rows: 1fr;
    padding-block: var(--padding);
    text-align: start;
    padding: 7px 22px;
    font-size: 16px;
    font-family: "Poppins", sans-serif !important;
    background-color: #fffcec;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
}

.details__desc-inner {
    min-height: 0;
    font-size: 16px;
    font-weight: 300;
    color: rgba(34, 34, 34, 1);
}

.details__summary {
    list-style: none;
}

.details__summary::marker {
    content: '';
}

.faq_background {
    background-image: linear-gradient(#faf8f0, #ffffff);
}

.faq_main_div {
    padding-top: 40px;
}

.academic_middle_slider {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
}

.academic_middle_slider {
    z-index: 99;
    top: 84%;
    width: 100%;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.academic_middle_slider ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 85px;
}

.academic_slider:hover .academic_middle_slider {
    top: 0%;
    background: #9a1d2ebd;
    animation: slideUp 0.8s ease-in-out forwards;
}

.domain_slider h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    color: #000;
}

.domain_slider h2 span {
    color: #a23333;
}

.domain_slider p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #8D8D8D;
    margin-top: 0px;
}

.domian_slider_dot .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    left: -97%;
    top: 37px;
    list-style: none;
    text-align: center;
}

.domian_slider_dot .slick-dots li button::before {
    font-size: 10px;
    color: rgb(164, 164, 164) !important;
    opacity: 1;
}

.domian_slider_dot .slick-dots li.slick-active button::before {
    color: #a23333 !important;
}

.domain_slider_content {
    height: 381px;
    position: absolute;
    display: flex;
    top: 0px;
    /* border-radius: 15px; */
    /* margin-right: 0px !important; */
    /* width: -webkit-fill-available; */
    align-items: flex-end;
    padding: 12px;
}

.domain_slider_text {
    padding: 0
}

.domain_img_div img {
    padding: 15px;
    padding-top: 0;
    position: absolute;
    top: -16px;
    left: 0;
    border-radius: 10px;
}

.domain_slider_div {
    border-radius: 15px;
    /* overflow: hidden; */
    background-color: #fff;
    height: 404px !important;
    margin-right: 10px !important;
    border: 1px solid #dee2e6;
    margin-top: 32px;
}

.domain_slider_div:hover {
    background-color: #9A1D2E;
    color: #fff !important;
}

.home_diffrent_img_section::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    /* background: linear-gradient(to bottom, transparent, #f0f0f0); */
    z-index: 1;
}

.domain_slider_div:hover .domain_img_div::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    /* background: linear-gradient(to bottom, transparent, #9A1D2E); */
}

.domain_slider_div:hover button {
    opacity: 1;
    transform: translateY(0);
    z-index: 9999;

}

.domain_slider_div:hover .domain_slider_text h5 {
    color: rgba(255, 255, 255, 1);

}

.domain_slider_div:hover .domain_slider_text p {
    color: rgba(255, 255, 255, 1);

}

.domain_slider_div button {
    opacity: 0;
    /* Start invisible */
    transform: translateY(20px);
    /* Move slightly down */
    z-index: -1;
    /* Keep it behind other elements initially */
    position: absolute;
    border-radius: 100px;
    padding: 7px 20px;
    font-size: 16px;
    font-weight: 400;
    border: none;
    background: #fff;
    box-shadow: 0 9px 10px #ececec;
    left: 40px;
    bottom: -10px;
    transition: opacity 0.4s ease, transform 0.4s ease;
    color: #9a1d2e;
    font-family: "Poppins", sans-serif;
}

.domain_slider_text h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: rgba(154, 29, 46, 1);
    text-overflow: ellipsis;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    max-height: calc(2 * 24px);
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

}

.domain_slider_text p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(141, 141, 141, 1);
    text-overflow: ellipsis;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    max-height: calc(2 * 24px);
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.all_section_margin {
    padding-left: 98px;
    padding-right: 98px;
}

.pulse-container {
    position: relative;
    display: inline-block;
}


/* Pulsing circles */
/* Pulsing circles */
.pulse {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    animation: pulse-wave 10s ease-in-out infinite;
    /* Extended duration for a slow effect */
}

/* Sizes and colors of each pulse ring */
.pulse-1 {
    width: 680px;
    height: 680px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #9a1d2e;
    animation-delay: 0s;
    /* Starts first */
    overflow: hidden;
}

.pulse-2 {
    width: 700px;
    height: 700px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #9a1d2e;
    animation-delay: 2s;
    /* Delayed for staggered effect */
    overflow: hidden;
}

.pulse-3 {
    width: 750px;
    height: 750px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #9a1d2e;
    animation-delay: 4s;
    /* Further delay for consistent rhythm */
    overflow: hidden;
}

/* Keyframes for smooth pulsing effect */
@keyframes pulse-wave {
    0% {
        opacity: 0.2;
        transform: translate(-50%, -50%) scale(1);
    }

    40% {
        opacity: 0.1;
        transform: translate(-50%, -50%) scale(1.4);
        /* Gradual scaling */
    }

    80% {
        opacity: 0.05;
        transform: translate(-50%, -50%) scale(1.8);
        /* Larger expansion */
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(2.2);
        /* Smooth fade-out */
    }
}

/* .pulse-4 {
    width: 600px;
    height: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #9a1d2e;
    animation-delay: 1s;
} */

/* /// here start only tabs top button */
.curriculum_tab_container {
    display: flex;
    background-color: #a23333;
    border-radius: 30px;
    overflow: hidden;
    width: 250px;
}

.curriculum_tab_button {
    flex: 1 1;
    padding: 7px 18px;
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    outline: none;
    font-weight: 500;
    font-size: 17px;
    font-family: "Poppins", sans-serif;
}

.curriculum_tab_button.active {
    background-color: #fff;
    color: #000;
    border-radius: 30px;
    transition: background-color 0.3s ease;
}

.curriculum_tab_container {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* /// here end tabs top button */
/* ///////////////////////////////////////////////////// */
.curriculum_slider .slick-dots {
    position: absolute;
    bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* Adjust spacing between dots */
    padding: 0;
    margin: 0;
    list-style: none;
}

/* Style each dot as a white circle */
.curriculum_slider .slick-dots li button {
    font-size: 0;
    /* Hide default button text */
    width: 9px;
    height: 9px;
    background-color: white;
    /* White background for inactive dots */
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, width 0.3s, height 0.3s;
}

.curriculum_slider .slick-dots li button::before {
    color: unset !important;
}

/* Active dot style: red and slightly larger */
.curriculum_slider .slick-dots li.slick-active button {
    background-color: #a71d2a;
    width: 14px;
    height: 14px;
    border: 2px solid white;
}

.curriculum_slider img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 15px
}

.igssc_text h2 {
    font-family: graphik_bold;
    font-size: 45px;
    line-height: 50px;
    margin-bottom: 20px;
}

.igssc_text h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: rgba(87, 87, 87, 1);
    margin-bottom: 20px;
}

.igssc_text p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: rgba(141, 141, 141, 1);
    margin-bottom: 25px;

}

.curriculum_custom_nav {
    border-bottom: 2px solid #ddd;
    text-align: center;
    align-items: center;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    display: flex;
    flex-wrap: nowrap;
}

.curriculum_custom_nav::-webkit-scrollbar {
    display: none;
}

.scrool_container_for_curriculum {
    position: relative;
}

.scrool_container_for_curriculum .left_btn {
    position: absolute;
    left: -20px;
    top: 5px;
    padding: 3px 1px;
}

.scrool_container_for_curriculum .right_btn {
    position: absolute;
    right: -29px;
    top: 5px;
    padding: 3px 1px;
}

.curriculum_custom_nav .nav-item {
    display: inline-block;
}

.curriculum_custom_nav .nav-link {
    font-family: "Poppins", sans-serif;
    color: rgba(0, 0, 0, 1);
    padding: 6px 5px;
    background-color: transparent;
    border: none;
    position: relative;
    font-size: 18px;
    font-weight: 500;
}

.curriculum_custom_nav .nav-link.active {
    color: #a10000;
    /* Red color for active tab */
}

.curriculum_custom_nav .nav-link.active::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #a10000;
    /* Red underline for active tab */
    position: absolute;
    bottom: -2px;
    left: 0;
}

.curriculum_all_card {
    margin-top: 25px;
    gap: 25px;
}

/* Separator Styling */
.separator {
    color: #ddd;
    margin: 0 5px;
    font-weight: bold;
}

/* Tab Content Styling */
.tab-content {
    font-size: 16px;
    color: #333;
    padding-bottom: 0px !important;
}

.curriculum_ccard {
    width: 145px;
    /* Adjust as needed */
    height: 130px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

/* Active card styles */

/* Hover effect for all cards */
.curriculum_ccard:hover {
    background-color: #a71d2a;
    /* Dark red on hover */
    color: #fff;
}

/* Title styling with underline */
.curriculum_ccard .card-title {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    margin-bottom: 0.5rem;
    -webkit-line-clamp: 3;
    max-height: calc(4* 23px);
    line-height: 23px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    position: relative;
}

.curriculum_title_name {
    position: relative;
}

.curriculum_title_name::after {
    content: "";
    display: block;
    width: 35%;
    height: 2px;
    background-color: currentColor;
    margin: 0.2rem auto;
    color: #a23333;
    margin-top: 10px;
    position: absolute;
    left: 36%;
}

.curriculum_ccard:hover .curriculum_title_name::after {
    /* background-color: #a71d2a; */
    color: #fff;
}

.curriculum_ccard .card-title::after {}

.curriculum_ccard p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.curriculum_ccard:hover .card-title::after {
    background-color: #fff;
}

.domain_img_img {
    height: 281px;
    object-fit: cover;
    border-radius: 25px !important;
}

.relted_right {
    left: 42px !important;
}

.related_left {
    left: -9px !important;
}

/* Pulse animation */
@keyframes pulse-wave {
    0% {
        transform: translate(-50%, -50%) scale(0.7);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -50%) scale(1.4);
        opacity: 0;
    }
}

.academic_slider_ul li span {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}


/* Styles specifically for Safari */
body.safari .academic_slider_cotnet {
    top: 40% !important;
}

body.safari .academic_slider:hover .academic_slider_cotnet {
    top: 0% !important;
    background: #9a1d2ebd !important;
    animation: slideUp 0.8s ease-in-out forwards !important;
}

body.safari .academic_slider_cotnet ul {
    list-style: none !important;
    /* margin-bottom: 4px !important; */
    padding-left: 0 !important;
    margin-bottom: 0px !important;
}

.academic_early_register_btn {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

body.for_safari .early_tabs_tadpoles_div {
    margin-left: 35px !important;
}

.academic_slider:hover ul li .discover_content_p p span {
    color: #fff !important;
}

.discover_content_p p {
    text-overflow: ellipsis;
    -webkit-line-clamp: 7;
    max-height: calc(7* 24px);
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.related_image {
    max-height: 23vh;
}

.campus_img_heigh {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}

@media (min-width: 1800px) and (max-width: 2500px) {
    .early_tabs_tadpoles_div p {
        width: 100%;
        margin-bottom: 25px;
    }

    .early_tabs_tadpoles_div h2 {
        font-size: 50px;
    }

    .early_tabs_tadpoles_div h5 {
        margin-bottom: 15px;
        font-size: 22px;
    }

    .early_tabs_tadpoles_div p {
        font-size: 18px;
    }

    .learn_article_slider_main_section .date-badge {
        top: 270px;
    }

    .home_slider_left_right_button {
        top: 490px;
    }

    .home_slider_left__button {
        top: 490px;
    }

    .academic_middle_slider {
        top: 72%;
    }

    .academic_slider_cotnet {
        top: 74%;

    }

    .kids_central {
        margin-bottom: 0;
    }

    .domian_slider_dot .slick-dots {
        left: -98%;
        top: 65px;
    }

    .tadpoles_div {
        padding-left: 216px;
        padding-right: 180px;
    }

    .early_tabs_img_section img {
        width: auto;
        height: 53vh;
        object-fit: cover;
        margin-left: 75px;
    }

    .early_tabs_container {
        width: 56%;
    }
}

@media (min-width: 1700px) and (max-width: 1800px) {
    .home_slider_left_right_button {
        top: 465px;
    }

    .home_slider_left__button {
        top: 465px
    }

    .learn_article_slider_main_section .date-badge {
        top: 175px;
    }

    .academic_middle_slider {
        top: 76%;
    }
}

@media (min-width: 1400px) and (max-width: 1500px) {
    .learn_article_slider_main_section .date-badge {
        top: 130px;
    }

    /* .academic_slider_cotnet {
        top: 75%;
    } */

    .igssc_text h2 {
        margin-bottom: 10px;
    }

    .igssc_text h6 {
        margin-bottom: 10px;
    }

    .igssc_text p {
        margin-bottom: 10px;
    }

}

@media (min-width: 1300px) and (max-width: 1400px) {
    .domian_slider_dot .slick-dots {
        top: 26px;
    }

    .domain_slider_text h5 {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
    }


    .early_year_heading_Section h2 {
        font-size: 55px;
    }

    .early_tabs_container {
        width: 71%;
    }

    .early_tabs_img_section img {
        height: auto;
    }

    .early_tabs_tadpoles_div h2 {
        font-family: graphik_bold;
        margin-bottom: 0px;
        font-size: 36px;
    }

    .tadpoles_div {
        padding-left: 65px;
        padding-right: 65px;
    }

    .learn_article_slider_main_section .date-badge {
        top: 124px;
    }

    .related_image {
        max-height: 22vh;
    }

    .academic_slider_cotnet {
        top: 45%;
    }

    .igssc_text h2 {
        margin-bottom: 8px;
    }

    .igssc_text h6 {
        margin-bottom: 8px;
    }

    .igssc_text p {

        margin-bottom: 0;
    }

    .curriculum_all_card {
        margin-top: 18px;
        gap: 15px;
    }

    .academic_middle_slider {
        top: 81% !important;
    }

    .academic_slider_ul li span {
        font-size: 13px;

    }

    .home_school_heading p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {
    .domian_slider_dot .slick-dots {
        top: 26px;
    }

    .domain_slider_text h5 {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
    }


    .early_year_heading_Section h2 {
        font-size: 55px;
    }

    .early_tabs_container {
        width: 71%;
    }

    .early_tabs_img_section img {
        height: auto;
    }

    .early_tabs_tadpoles_div h2 {
        font-family: graphik_bold;
        margin-bottom: 0px;
        font-size: 36px;
    }

    .tadpoles_div {
        padding-left: 65px;
        padding-right: 65px;
    }

    .learn_article_slider_main_section .date-badge {
        top: 120px;
    }

    .related_image {
        max-height: 22vh;
    }

    .academic_slider_cotnet {
        top: 45%;
    }

    @media not all and (min-resolution:.001dpcm) {
        .academic_slider_cotnet {
            top: 65% !important;
        }
    }

    .igssc_text h2 {
        margin-bottom: 8px;
    }

    .igssc_text h6 {
        margin-bottom: 8px;
    }

    .igssc_text p {

        margin-bottom: 0;
    }

    .curriculum_all_card {
        margin-top: 18px;
        gap: 15px;
    }

    /* .academic_middle_slider {
        top: 81% !important;
    } */
    .academic_slider_ul li span {
        font-size: 13px;

    }

    .home_school_heading p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .early_year_heading_Section h2 {
        font-size: 40px;
        line-height: 50px;
    }


    .sec_adjust {
        margin-bottom: 0px;
        margin-top: 130px;
    }

    .calendar_school_text h2 {
        font-size: 40px;
    }



    .early_year_heading_Section {
        margin-left: 37px;
        padding: 0 0px;
    }

    .tadpoles_div {
        padding-left: 15px;
        padding-right: 15px;
    }

    .early_tabs_tadpoles_div h2 {
        margin-bottom: 3px;
        font-size: 35px;
    }

    .each_stage {
        width: 80%;
    }

    .each_stage_main_div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .early_tabs_tadpoles_div {
        margin-left: 25px;
    }

    .all_section_margin {
        padding-left: 15px;
        padding-right: 15px;
    }

    .relted_right {
        top: 358px;
    }

    .related_left {
        top: 358px;
    }

    .academic_early_store button {
        margin-top: 9px;
    }

    .early_year_heading_Section p {
        font-size: 16px;
        line-height: 27px;
    }

    .kids_central {
        font-size: 25px;
    }

    .domain_slider_text h5 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 36px;
    }

    .domain_slider_text p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .igssc_text h2 {
        font-size: 35px;
        line-height: 32px;
    }

    .learn_article_slider_main_section .date-badge {
        top: 144px;
    }

    .early_tabs_tadpoles_div p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .academic_slider_cotnet {
        top: 56%;
    }

    .academic_slider_tabs li span p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
    }

    .subscription_slider_main_div .card-title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .subscription_slider_main_div .card-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .faq_tabs {
        width: 100%;
    }

    .faq_main_file {
        margin-top: 98px;
    }

    /* .curriculum_slider img {
        height: 39vh;
    } */
    .academic_early_message {
        width: 100%;
    }

    .early_tabs_img_section img {
        width: auto;
        height: auto !important;
        object-fit: cover;
    }

    .home_slider_left__button {
        top: 109%;
        left: 16px;
        transform: translateY(-50%);
    }

    .home_slider_left_right_button {
        top: 109%;
        left: 16px;
        transform: translateY(-50%);
    }

}

@media only screen and (max-width: 768px) {
    .sec_adjust {
        flex-direction: column;
    }

    .early_year_heading_Section {
        margin-left: 0px;
        padding: 0px 0px;
        text-align: center !important;
        margin-top: 35px;
    }

    .early_year_heading_Section h4 {
        font-size: 20px;
        margin-bottom: 6px;
    }

    .early_year_heading_Section h2 {
        font-size: 25px;
        line-height: 30px;
    }

    .early_year_heading_Section p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .pulse-1 {
        width: 480px;
        height: 480px;
    }

    .pulse-2 {
        width: 500px;
        height: 500px;
    }

    .pulse-3 {
        width: 520px;
        height: 520px;

    }

    .content-container {
        flex-direction: row;
    }

    .text-section {
        text-align: left;
    }

    .image-section {
        text-align: center;
    }

    .tadpoles_div {
        padding-left: 0px;
        padding-right: 0px;
    }

    .tadpoles_div .row {
        display: flex;
        flex-direction: column-reverse;
        gap: 30px;
    }

    .early_tabs_tadpoles_div h2 {
        margin-bottom: 9px;
        font-size: 25px;
    }

    .early_tabs_tadpoles_div p {
        font-size: 14px;
        line-height: 24px;
    }

    .all_section_margin {
        padding-left: 0px;
        padding-right: 0px;
    }

    .faq_details__title {
        line-height: 24px !important;
    }

    .academic_early_message {
        background-color: #9A1D2E;
        padding: 0px 0px;
        display: flex;
        flex-direction: column;
        /* align-items: flex-start !important; */
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .academic_early_message p {
        width: 80% !important;
        margin-top: 25px;
        margin-bottom: 35px;
    }

    .academic_early_message button {
        /* margin-left: 25px; */
        margin-bottom: 20px;

    }

    .academics_all_section {
        padding-left: 15px;
        padding-right: 0px;
        padding-top: 25px;
    }

    .learn_article_slider_main_section .date-badge {
        /* top: 205px; */
        top: 46%;
        left: 0px;
        transform: translateY(-50%);
    }

    .learn_article_slider_main_section {
        margin-left: 1px;
        height: 265px;
    }

    .academic_early_store {
        align-items: flex-end;
        margin-right: 0px;
        flex-direction: column;
    }

    .subscription_slider_main_div .card-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

    }

    .subscription_slider_main_div .card-text {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
    }

    .home_slider_left__button {
        top: 109%;
        left: 16px;
        transform: translateY(-50%);
    }

    .home_slider_left_right_button {
        top: 109%;
        left: 16px;
        transform: translateY(-50%);
    }

    .faq_details__title {
        padding: 10px 35px 10px 13px;
    }

    .academic_slider_cotnet {
        top: 50%;
    }

    .kids_central {
        font-family: graphik_bold;
        font-size: 25px;
        font-weight: 600;
    }

    .cool_cats {
        font-size: 16px;
    }

    .academic_slider_ul li span {
        font-size: 16px;
    }

    .academic_slider_cotnet ul {
        line-height: 24px;
    }

    .early_tabs_container {
        display: flex;
        flex-wrap: nowrap;
        width: 94%;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
    }

    .early_tabs_container .tab-item {
        padding: 9px 6px;
        width: 114px;
    }

    .board_Section {
        width: 96%;
        flex-direction: column-reverse;
        padding-top: 33px;
        /* align-items: flex-start !important; */
    }

    .board_Section_text h2 {
        font-family: graphik_bold;
        font-size: 25px;
        margin-bottom: 16px;
        line-height: 35px;
    }

    .domain_slider {
        padding-left: 20px;
        padding-right: 10px;
    }

    .domain_slider h2 br {
        display: none;
    }

    .domain_slider h2 {
        font-size: 25px;
    }

    .domain_slider p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .domian_slider_dot {
        padding-left: 20px;
    }

    .domain_slider_content {
        height: 355px;
    }

    .domain_slider_div {
        height: 392px !important;

    }

    .register_now_btn {
        align-items: flex-start;
        display: flex;
        margin-left: 10p;
        padding-left: 25px;
    }

    .tabs_all_content .row {
        display: flex;
        flex-direction: column-reverse;
    }

    .igssc_text h2 {
        font-size: 25px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .tabs_all_content .row .col-8 {
        width: 100%;
        margin-bottom: 45px;
    }

    .tabs_all_content .row .col-4 {
        width: 100%;
        margin-bottom: 15px;
    }

    .tabs_all_content {
        padding: 0 !important;
    }

    .curriculum_ccard {
        width: 150px;
    }

    .curriculum_ccard .card-title {
        font-size: 16px;
    }

    .early_years_section {
        margin-top: 105px;
    }

    /* ///////////// */
    .learn_article_slider_main_section.active-slide .card-title {
        color: #9a1d2e !important;
        /* Active title color for mobile view */
    }

    /* ////////// */
    .domain_slide {
        background-color: #9A1D2E !important;
        color: #fff !important;
    }

    .domain_slide .domain_slider_text p {
        color: rgba(255, 255, 255, 1);

    }

    .domain_slider_text p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .domain_slider_text h5 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .domain_slide h5 {
        color: rgba(255, 255, 255, 1) !important;
        /* margin-bottom: 10px; */

    }

    .domain_slide button {
        opacity: 1;
        transform: translateY(0);
        z-index: 9999;

    }

    .related_slide .card-title {
        color: #9a1d2e !important;
        transition: color 0.3s ease;
    }

    .faq_main_div {
        padding-top: 15px;
    }

    .faq_details__title {
        /* font-weight: 300 !important; */
        font-size: 15px !important;
        /* padding: 5px 9px; */
    }

    .faq_main_mobile_div {
        margin-top: 115px;
    }

    .sec_adjust {
        margin-bottom: 10px;
    }

    .academic_early_register_btn {
        display: unset;
    }

    .academic_slider:hover .academic_slider_cotnet {
        top: 0%;
        background: #9a1d2ebd;
        animation: slideUp 0.8s ease-in-out forwards;
    }

    body.for_safari .early_tabs_tadpoles_div {
        margin-left: 0px !important;
    }

    /* body.safari .academic_slider_cotnet {
        top: 99% !important;
    } */
    .discover_content_p p {
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        max-height: calc(5* 24px);
        line-height: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .related_image {
        max-height: 17vh;
    }

    .subscription_slider_main_div .card-text {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #8D8D8D;
        overflow: hidden;
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 2;
        -webkit-line-clamp: 1;
        max-height: calc(1* 24px);
        line-height: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .scrool_container_for_curriculum .left_btn {
        position: absolute;
        left: -23px;
        top: 19px;
        padding: 7px 2px;
        display: none;
    }

    .scrool_container_for_curriculum .right_btn {
        position: absolute;
        right: -29px;
        top: 21px;
        padding: 7px 2px;
        display: none;
    }

    body.safari .academic_slider_cotnet {
        top: 33% !important;
    }
    .campus_img_heigh {
        width: 100%;
        height: 31vh;
        object-fit: cover;
    }

}
