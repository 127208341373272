@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');


.contact_us_top_section {
    /* background: linear-gradient(89.83deg, rgb(13 13 13 / 82%) 15.86%, #9A1D2E 99.94%); */
    padding: 0;
    position: relative;
    height: 750px;
    /* background-color: #f4f0dd; */
}

.contact_us {
    /* background: linear-gradient(89.83deg, rgb(13 13 13 / 82%) 15.86%, #9A1D2E 99.94%);
    padding: 65px; */
    position: absolute;
    width: 100%;
    left: 0;
    /* height: 54vh; */
}

.contact_us img {
    height: 86vh;
    width: 100%;
    object-fit: cover;
    filter: grayscale(1);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) -78.07%, #000000 100%);
}

.contact_us_form_section {
    background-color: #fff;
    border-radius: 15px;
    position: relative;
    top: 475px;
    width: 88%;
    padding: 25px 10px;
    padding-bottom: 25px;
    left: 88px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}



.contact-us-section {
    position: relative;
    color: #fff;
    /* White text */
    overflow: hidden;
}

.contact-us-bg {
    width: 100%;
    height: auto;
    object-fit: cover;
    filter: brightness(0.4);
    /* Darken the background image */
}

.contact-us-content {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 100px;
}

.contact_us_left h2 {
    font-family: graphik_bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
}

.contact_us_left h2 span {
    color: #D4D4D4;

}

.contact_us_left h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
    color: #D4D4D4;
}

.contact_us_left h4 span {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
    color: #F4F4F4;
}

.contact_us_right {
    text-align: start;
}

.contact_us_right p {
    color: #E9E9E9;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 0;
}

.contact_us_hear {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    align-items: end;
}

.contact_us_enquiry_form {
    text-align: start;
    margin-top: 10px;
}

.contact_us_enquiry_form h3 {
    color: #161616;
    font-family: graphik_medium;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 0;
}

.enquiry_submit {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}

.query_btn {
    margin-top: 0 !important;
}

.contact_us_query_text {
    display: flex;
    text-align: start;
    align-items: flex-start;
    /* width: 100%; */
    align-items: center;
}

.contact_us_query_text p {
    font-family: "Instrument Sans", sans-serif;
    color: #575757;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
}

.contact_us_campus {
    display: flex;
    background-color: #a23333;
    border-radius: 30px;
    overflow: hidden;
    width: 348px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.rr_campus_main_section {
    padding-top: 60px;
    padding-bottom: 35px;
    padding-left: 84px;
    padding-right: 84px;
}

.op_campus_div {
    margin-top: 42px;
}

.contact_us_our_campus h2 {
    font-family: graphik_medium;
    font-weight: 700;
    font-size: 44px;
    color: #000;

}

.contact_us_our_campus p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    color: #8D8D8D;
    width: 86%;
}

.contact_us_location {
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    list-style: none;
}

.contact_us_location h5 {
    font-family: "Poppins";
    font-size: 22px;
    line-height: 29px;
    font-weight: 500;
    color: #000;
}

.contact_us_olympia {
    margin-top: 15px;
}

.contact_us_location ul li svg {
    background: #9a1d2e;
    padding: 7px;
    width: 34px;
    border-radius: 50%;
    height: 33px;
    margin-right: 4px;
    color: #fff;
}

.contact_us_olympia li {
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
}

.contact_address_tag {
    color: #575757;
    font-family: "Poppins";
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.contact_us_olympia .contact {
    text-align: center;
    align-items: center;
}

.contact_us_map_section {
    padding-left: 84px;
    padding-right: 84px;
    padding-bottom: 35px;
}

.kc_application {
    display: flex;
    flex-direction: column;
}




@media (min-width: 1600px) and (max-width: 2500px) {
    .contact_us_form_section {
        background-color: #fff;
        border-radius: 15px;
        position: relative;
        top: 532px;
        width: 91%;
        padding: 38px 20px;
        padding-bottom: 25px;
        left: 88px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }

    .contact_us_top_section {
        height: 757px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .contact_us img {
        height: 74vh;
        width: 100%;
        object-fit: cover;
        filter: grayscale(1);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) -78.07%, #000000 100%);
    }

    .contact_us_our_campus p {
        width: 100%;
        margin-bottom: 4px;
    }

    .contact_us_our_campus h2 {
        font-size: 40px;
        margin-bottom: 0;
    }

    .contact_us_olympia {
        margin-top: 5px;
    }

    .contact_us_form_section {
        width: 86%;
        top: 419px;
    }

    .contact_us_top_section {
        height: 630px;
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .contact-us-content {
        top: 0px;
        padding: 43px;
    }

    .contact_us_form_section {
        top: 440px;
        width: 91%;
        left: 40px;
    }

    .contact_us_top_section {
        height: 725px;
    }

    .contact_us_our_campus h2 {
        font-size: 40px;
    }

    .contact_us_our_campus p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width: 100%;
    }

    .fa_locate_One {
        width: 42px !important;
    }

    .contact_us_left h2 {
        font-size: 40px;
        line-height: 36px;
    }

    .rr_campus_tabs {
        height: 100%;
    }
    .contact_us img {
        height: 51vh;
       
    }
}

@media only screen and (max-width: 768px) {
    .rr_campus_main_section {
        padding-top: 60px;
        padding-bottom: 35px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .contact-us-content {
        position: absolute;
        top: -155px;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
    }

    .contact_us_top_section {
        height: 882px;
    }

    .contact_us_form_section {
        top: 300px;
        width: 100%;
        left: 0px;
    }

    .contact_us_left h2 {
        font-size: 25px;
        line-height: 28px;
    }

    .contact_us_left {
        text-align: center !important;
    }

    .enquiry_submit {
        flex-direction: column;
        gap: 11px;
    }

    .contact_us_checkbox_mobile {
        display: flex;
    }

    .contact_us_map_section {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }

    .contact_us_our_campus h2 {
        font-size: 25px;
    }

    .contact_us_location {
        text-align: start !important;
    }

    .fa_locate {
        width: 41px !important;
    }

    .fa_locate_One {
        width: 55px !important;
    }

    .contact_us_our_campus p {
        width: 100%;
    }

    .curriculum_mobile {
        margin-bottom: 0 !important;
    }

}
